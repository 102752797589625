@charset "utf-8";

/*
%%%%%%% Table of CSS %%%%%%%

1.General code
    1.1 Typography
    1.2 Space margins and padding
    1.3 forms
    1.4 Buttons
    1.5 Section-Colors-&-Backgrounds
    1.6 Modal & popups
2.Homepages
    2.1 Navigation
    2.2 Categories
    2.3 Recent Order
    2.4 Explore Collection
    2.5 Page Banner
    2.6 Advertisement Slider
    2.7 Blog Section
    2.8 Footer
        2.8.1 Footer Top
    2.10 Copyright
3.Blog Grid
4.Ex Deals
5.Restaurent
6.Add Restaurent
7.List View
8.Login & register
9.Checkout
10.Final Order
11.Geo Locator
12.About Us
*/
/*Google Font*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Poppins:400,400i,700,700i&display=swap');

/*======================
1.General Code
========================*/

.bg-norepeat {
    background-repeat: no-repeat;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.bg-bottomright {
    background-position: bottom right;
}

.bg-topright {
    background-position: top right;
}

html {
    overflow-x: hidden;
}

button:focus,
*:focus {
    outline: none;
}

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.8;
    font-weight: 400;
    color: #6b6b83;
    background: #ffffff;
    font-size: 14px;
    overflow: hidden;
}

button {
    background: transparent;
    border: none;
    padding: 0;
}

label {
    font-weight: 600;
    text-transform: uppercase;
}

.align-item-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.p-relative {
    position: relative;
}

.normal-bg {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.after-none:after,
.none {
    display: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.overlay-bg {
    background: #3c3e41;
    opacity: 0.5;
}

.overlay-2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    z-index: 1;
}

.transform-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
    font-size: inherit;
    margin: 0;
}

.image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.animate-img {
    position: relative;
    overflow: hidden;
}

.animate-img img {
    transition: .3s;
}

.animate-img:hover img {
    transform: scale(1.1);
}

.swiper-button-next,
.swiper-button-prev {
    box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    background: #268968;
    top: 52%;
    transform: translate(0%, -48%);
    transition: .3s;
    opacity: 0;
    visibility: hidden;
}

.swiper-button-next {
    right: 0;
}

.swiper-button-prev {
    left: 0;
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
    opacity: 1;
    visibility: visible;
}

.swiper-container:hover .swiper-button-next {
    right: 10px;
}

.swiper-container:hover .swiper-button-prev {
    left: 10px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 14px;
    font-weight: 900;
    color: #fff;
}

.swiper-button-disabled {
    display: none;
}

.back-btn button {
    box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    background: #fff;
    font-size: 16px;
    font-weight: 900;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.u-line {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.banner-adv {
    position: relative;
    height: 70px;
    background: #268968;
    width: 100%;
    overflow: hidden;
}

.banner-adv .text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-decoration: underline;
}

.banner-adv2 .close-banner,
.banner-adv .close-banner {
    cursor: pointer;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: block;
    z-index: 9;
    color: #fff;
}

.banner-adv2 .close-banner:before,
.banner-adv2 .close-banner:after,
.banner-adv .close-banner:before,
.banner-adv .close-banner:after {
    border-bottom: 2px solid;
    content: "";
    position: absolute;
    left: 12px;
    top: 19px;
    width: 15px;
}

.banner-adv2 .close-banner:before,
.banner-adv .close-banner:before {
    transform: rotate(-45deg);
}

.banner-adv2 .close-banner:after,
.banner-adv .close-banner:after {
    transform: rotate(45deg);
}

.banner-adv2 .close-banner {
    display: none;
}

.banner-adv2 {
    position: relative;
    height: 100px;
    background: #fff;
    width: 100%;
    overflow: hidden;
}

.banner-adv2>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.banner-adv2 .text {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
}

.banner-adv2 .text img {
    margin: 0 25px;
}

#scrollstyle-4::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#scrollstyle-4::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#scrollstyle-4::-webkit-scrollbar-thumb {
    background-color: #268968;
}

.main-box {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    border-radius: 3px;
    background: #fff;
}

.section-header {
    margin: 0 auto;
    max-width: 450px;
    padding-bottom: 30px;
    text-align: center;
}

.section-header-left {
    margin: 0;
    text-align: left;
    max-width: 100%;
    padding-bottom: 30px;
}
.section-header-left h3.header-title,
.section-header-left h2,
.deals-heading h2{
  margin-bottom: 10px;
}
.section-header-left p,
.deals-heading p{
  margin-bottom: 0;
  font-size: 16px;
}
.deals-heading a{
  margin-top: 20px;
}

.section-header-right {
    margin: 0;
    text-align: right;
    max-width: 100%;
    padding-bottom: 20px;
}

.section-header-style-2 .header-title,
.section-header-left .header-title,
.section-header-right .header-title,
.section-header .header-title {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 700;
    display: flex;
}

.section-header-style-2 {
    margin: 0 auto;
    max-width: 660px;
    padding-bottom: 40px;
    text-align: center;
}

.section-header-style-2 .sub-title {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 10px;
}

.custom-container {
    padding-left: 50px;
    padding-right: 50px;
}

/*-------animation--------*/
@keyframes ripple {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.75);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

/*======================
1.1 Typography
========================*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 1.2;
    color: #3c3e41;
}

h1 {
    font-size: 62px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

a {
    color: #268968;
    text-decoration: none;
    transition: 0.5s;
}

a:focus,
a:hover {
    color: #268968;
    text-decoration: none;
    transition: 0.5s;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

ul li,
ol li {
    margin: 0px;
    padding: 0px;
    position: relative;
    list-style: none;
}

blockquote {
    position: relative;
    padding: 30px;
    margin: 20px 0;
    border-left: 3px solid #268968;
    font-size: 18px;
}

blockquote p {
    font-size: 18px;
    font-style: italic;
    margin: 0;
    word-break: break-word;
}

blockquote cite {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #268968;
}

blockquote h6 {
    color: #3c3e41;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

table th,
table td {
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 15px;
}

td.remove {
    width: 30px;
}

table {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 15px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fw-100 {
    font-weight: 100;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.title-2 {
    display: flex;
    align-items: baseline;
}

.title-2 small {
    margin-left: 30px;
}

.custom-svg {
    display: block;
}

.custom-svg svg {
    width: 1.25rem;
}

.custom-svg.svg-lg svg {
    width: 2rem;
}

.custom-svg.svg-xl svg {
    width: 4rem;
}

.custom-svg svg path,
.custom-svg svg rect,
.custom-svg svg circle {
    -webkit-transition: fill .3s ease;
    -o-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #268968;
    transition: .3s;
}

.custom-svg:hover svg path,
.custom-svg:hover svg rect,
.custom-svg:hover svg circle {
    fill: #268968;
}


/*pagination*/

.custom-pagination {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-pagination li {
    margin: 0 5px;
}

.page-link:focus {
    box-shadow: none;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-pagination .page-link {
    padding: 0;
    color: #3c3e41;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .1);
    justify-content: center;
}


.custom-pagination .page-item:not(.active) .page-link:hover {
    color: #268968;
    background-color: #f9f9f9;
}

.custom-pagination .page-item.active .page-link {
    background: #268968;
    color: #fff;
    border-color: #268968;
}

/*======================
1.2 Space margins and padding
========================*/

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.full-width {
    width: 100%;
}

.section-padding {
    padding: 80px 0;
}

.section-padding-top {
    padding-top: 80px;
}

.section-padding-bottom {
    padding-bottom: 80px;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-tb-10 {
    padding: 10px 0;
}

.padding-tb-20 {
    padding: 20px 0;
}

.padding-15 {
    padding: 15px;
}

.padding-20 {
    padding: 20px;
}

.padding-30 {
    padding: 30px;
}

.padding-tb-80 {
    padding: 80px 0;
}

.mb-xl-10 {
    margin-bottom: 10px;
}

.mb-xl-20 {
    margin-bottom: 20px;
}

.mb-xl-30 {
    margin-bottom: 30px;
}

.pb-xl-10 {
    padding-bottom: 10px;
}

.pb-xl-20 {
    padding-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}
.pagination-loader{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
/*==================
1.3. Forms
====================*/
.form-group {
    margin-bottom: 20px;
}

.form-control {
    font-size: 14px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057bd;
    outline: 0;
    font-size: 14px;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.form-control::placeholder,
input[type=text]::placeholder {
    color: #c3c3c3;
}

.form-control-submit {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    padding: 20px 16px;
    height: 100%;
}

label.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

label.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    line-height: normal;
    font-size: 14px;
}

label.custom-checkbox:last-child {
    margin-bottom: 10px;
}

/* Create a custom checkbox */

label.custom-checkbox .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    border: 1px solid #d4d4db;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */

label.custom-checkbox:hover input~.checkmark {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
}

/* When the checkbox is checked, add a blue background */

label.custom-checkbox input:checked~.checkmark {
    background-color: #268968;
    border: 1px solid rgba(0, 0, 0, .1);
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

label.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

label.custom-checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/*==================
1.4. Buttons
====================*/

.btn-first {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 9px 16px;
    font-size: 13px;
    line-height: 1.595;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.green-btn {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.green-btn:hover,
.green-btn:focus {
    border-color: #28a745;
    background: #fff;
    color: #28a745;
}

.paypal-btn {
    color: #fff;
    background-color: #ffc439;
    border-color: #ffc439;
}

.paypal-btn:hover,
.paypal-btn:focus {
    border-color: #ffc439;
    background: #ffc439;
    color: #fff;
}

.category-btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #ffffff94;
    border: 1px solid transparent;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 1.595;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #3c3e41;
    font-weight: 600;
}

.category-btn:hover,
.category-btn:focus {
    border-color: #268968;
    background: #268968;
    color: #fff;
}

.btn-second {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 15px;
    font-size: 14px;
    line-height: 1.51741;
    border-radius: 4px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.btn-second.btn-sm {
    font-size: 13px;
    padding: 12px 15px;
}

.btn-second img {
    float: left;
    left: -8px;
    position: relative;
    width: 22px;
    height: 22px;
}

.btn-submit {
    color: #fff;
    background: transparent linear-gradient(90deg, #e28718 0%, #F64B3C 100%) 0% 0% no-repeat padding-box;
    border-color: #e5801d;
}

.btn-submit:hover,
.btn-submit:focus {
    border-color: #248162;
    color: #fff;
    background: transparent linear-gradient(90deg, #268968 0%, #17a872 100%) 0% 0% no-repeat padding-box;
}

.btn-light {
    color: #3c3e41;
    background: #fff;
    border-color: #fff;
}

.btn-light:hover,
.btn-light:focus {
    border-color: #f9f9f9;
    background: #f9f9f9;
    color: #3c3e41;
}

.btn-facebook {
    color: #fff;
    background: #3b5998;
    border-color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus {
    border-color: #2d4373;
    background: #2d4373;
    color: #fff;
}

.btn-google {
    color: #fff;
    background: #4285f4;
    border-color: #4285f4;
}

.btn-google:focus,
.btn-google:hover {
    border-color: #1266f1;
    background: #1266f1;
    color: #fff;
}

span.circle-tag {
    height: 36px;
    width: 36px;
    background: #fff;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.square-tag>img,
span.rectangle-tag>img,
span.circle-tag>img {
    padding: 8px;
}

span.rectangle-tag {
    height: 30px;
    width: auto;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    letter-spacing: 2px;
    padding: 0 8px;
}

span.rectangle-tag+span.rectangle-tag {
    margin: 0 2px;
}

span.square-tag {
    height: 35px;
    width: 40px;
    background: transparent;
    border-radius: 3px;
    font-size: 16px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.share-tag {
    height: 36px;
    width: 36px;
    background: #fff;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.deal-tag {
    border-radius: 3px;
    padding: 4px 10px;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(66, 41, 163, .08), 0 0 0 1px rgba(66, 41, 163, .08);
}

span.add-product {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;
    color: #268968;
    box-shadow: 0 1px 5px 0 rgba(66, 41, 163, .08), 0 0 0 1px rgba(66, 41, 163, .08);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.add-product i {
    font-size: 12px;
}

span.type-tag {
    padding: 3px 15px;
    position: absolute;
    bottom: 0;
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
}

.badge {
    padding: 5px 9px;
    font-size: 12px;
}

.badge.badge-danger {
    color: #dc3545;
    background-color: #ffe0e3;
}

.badge.badge-warning {
    color: #ffc107;
    background-color: #fff7df;
}


/*==================
1.5. Section-Colors-&-Backgrounds
====================*/

.bg-light-white {
    background-color: #f4f4f4;
}

.bg-light-theme {
    background-color: #fbfbfc;
}

.bg-light-green {
    background-color: #268968;
}

.bg-orange {
    background-color: #ea6e27;
}

.bg-red {
    background-color: #ff0000;
}

.bg-gradient-red {
    background-image: linear-gradient(to right, #e05d41, #bf3011);
}

.bg-yellow {
    background-color: #ffc000;
}

.bg-green {
    background-color: #4aa110;
}

.bg-gradient-green {
    background-image: linear-gradient(to bottom, #268968, #40503c);
}

.bg-gradient-orange {
    background-image: linear-gradient(to bottom, #c55a11, #ed7d31);
}

.bg-black {
    background-color: #3c3e41;
}

.bg-light-grey {
    background-color: #fafcfc
}

.bg-light-orange {
    background-color: #eaf6e3;
}

.border-white {
    border-color: #ffffff;
}

/*Colors*/

.text-light-black {
    color: #3c3e41;
}

.text-black {
    color: #3c3e41;
}

.text-orange {
    color: #fb6100;
}

.text-custom-white {
    color: #ffffff;
}

.text-light-white {
    color: #6b6b83;
}

.text-light-orange {
    color: #f6f4f0;
}

.text-dark-white {
    color: #3c3e41;
}

.text-dark-green {
    color: #268968;
}

.text-yellow {
    color: #fcb71b;
}

.text-light-green {
    color: #268968;
}

.text-success {
    color: #13aa37;
}

.text-custom-orange {
    color: #268968;
}

/*======================
1.6 Modal & Popups
========================*/

#offer .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

#offer.modal {
    height: auto;
    top: 50%;
    left: 0%;
    right: 0;
    transform: translate(0%, -50%);
}

.offer-content {
    text-align: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 30px 10px;
    position: relative;
    min-height: 100%;
    background: linear-gradient(0deg, #6a1d89a8, #6a1d89a8), url('../img/offer.jpg');
    background-size: cover;
}

.offer-content p {
    max-width: 300px;
    margin: 0 auto 30px;
}

.offer-content .modal-action .yes {
    padding: 6px 40px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background: #ffcd34;
    margin: 0 8px;
    border-radius: 50px;
}

.offer-content .modal-action .no {
    padding: 6px 40px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background: #268968;
    margin: 0 8px;
    border-radius: 50px;
}

#offer .modal-header {
    border-bottom: 0;
}

#offer .modal-header .close {
    position: absolute;
    color: #fff;
    right: 0;
    padding: 20px;
    margin: 0;
}

.offer-content h2 {
    text-transform: uppercase;
    font-size: 50px;
}

.offer-content h2 small {
    display: block;
    font-size: 30px;
    text-transform: lowercase;
}

#address-box .modal-content,
#search-box .modal-content {
    background: #fbfbfc;
}

#address-box .modal-header,
#search-box .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}


#address-box .modal-header .close,
#search-box .modal-header .close {
    margin: 0;
    display: block;
    text-align: left;
    color: #3c3e41;
    opacity: .4;
    padding: 0;
    font-size: 35px;
    transition: .3s;
    font-weight: 200;
    line-height: 1;
    margin-left: 40px;
    margin-bottom: 0;
}

#address-box .modal-header .close:hover,
#search-box .modal-header .close:hover {
    opacity: .8;
}

#address-box .modal-title,
#search-box .modal-title {
    font-size: 20px;
}

#address-box .modal-body,
#search-box .modal-body {
    padding: 20px;
}

.search-results .search-result {
    display: flex;
    align-items: flex-start;
    padding: 20px 0 0;
}

.search-results .search-result i {
    font-size: 18px;
    color: #6B6B83;
    margin-right: 10px;
}

.search-results .search-result h6 {
    margin-bottom: 5px;
    transition: .3s;
}

.search-results .search-result:hover h6 {
    color: #268968;
}

.search-results .search-result span {
    font-size: 13px;
    color: #9c9c9c;
}
.leaflet-popup-content-wrapper{
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}
.leaflet-popup-content{
    margin: 0;
}
.mapboxgl-popup-content {
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 10px 15px;
    pointer-events: auto;
}
.mapboxgl-popup-content img{
    width: 100%;
}
.leaflet-container a.leaflet-popup-close-button{
    color: #000;
    padding: 0;
    font-size: 12px;
    width: auto;
    height: auto;
}
/*======================
2. Homepages
========================*/
/*Navigation*/

.header {
    display: flex;
    width: 100%;
    z-index: 999;
    transition: all .2s ease;
    background-color: #fff;
}

.header .full-width .container-fluid {
    padding-left: 55px;
    padding-right: 55px;
}

.header .mainNavCol {
    display: flex;
    align-items: center;
}

.header .logo {
    flex: 1 150px;
    justify-content: center;
    max-width: 160px;
    margin-right: 55px;
}

.header .main-search {
    flex: 1;
}

.header .right-side,
.header .user-details>a,
.header .gem-points a,
.header .right-side>.cart-btn>a,
.header .right-side .catring>a {
    padding: 25px 0;
    justify-content: center;
    display: flex;
    font-weight: 600;
    align-items: center;
}

.header .user-details>a img {
    width: 25px;
}

.header .right-side {
    padding: 0;
}

.header .gem-points i,
.header .right-side .cart-btn a i {
    font-size: 22px;
}

.header .user-details>a>span,
.header .gem-points span {
    margin-left: 8px;
}

.header .right-side .catring,
.header .right-side .user-details,
.header .right-side .user-details,
.header .right-side .gem-points,
.header .right-side .cart-btn {
    margin: 0 15px;
}

.header .right-side .cart-btn:last-child {
    margin-right: 0;
}

.header .right-side .user-details>a>span {
    padding-right: 30px;
}

.header .mobile-address .delivery-add span,
.header .search-form span {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0;
    position: relative;
}

.sorting-addressbox .full-address {
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}

.header .mobile-address .delivery-add,
.header .search-form a.delivery-add {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 0 15px;
}

.header .search-form a.delivery-add {
    display: inline-flex;
}

.header .search-form a.delivery-add {
    padding: 15px 0;
}

.header .search-form div.icon {
    margin-right: 10px;
}

.header .mobile-address .delivery-add span.address,
.header .search-form span.address {
    padding-right: 25px;
}

.header .mobile-address .delivery-add span.address:before,
.header .mobile-address .delivery-add span.address:after,
.header .search-form span.address:before,
.header .search-form span.address:after,
.header .user-details>a>span:before,
.header .user-details>a>span:after {
    transition: all 0.3s ease-in-out;
    background-color: #268968;
    position: absolute;
    content: '';
    height: 2px;
    width: 8px;
    top: 20px;
}

.header .user-details>a>span:before,
.header .user-details>a>span:after {
    top: 36px;
}

.header .user-details>a>span:before,
.header .mobile-address .delivery-add span.address:before,
.header .search-form span.address:before {
    transform: rotate(45deg);
    right: 10px;
}

.header .user-details>a>span:after,
.header .mobile-address .delivery-add span.address:after,
.header .search-form span.address:after {
    transform: rotate(-45deg);
    right: 6px;
}

.header .search-form a.delivery-add.open span.address:before {
    transform: rotate(-45deg);
}

.header .search-form a.delivery-add.open span.address:after {
    transform: rotate(45deg);
}

.location-picker,
.header .search-form .search-box {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.header .search-form .search-box input {
    padding: 10px 20px;
}

.header .right-side .cart-btn .user-alert-cart,
.header .right-side .cart-btn .user-alert-notification {
    background: #ff8e98;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    right: auto;
    color: #fff;
    display: flex;
    align-items: center;
    line-height: normal;
    height: auto;
    font-size: 10px;
    top: 14px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .right-side .cart-btn .user-alert-notification {
    width: 5px;
    height: 5px;
    display: none;
}

.header .right-side .cart-btn .user-alert-cart {
    height: 18px;
    right: 2px;
}

.location-picker {
    display: flex;
    position: absolute;
    top: 104%;
    left: 0;
    width: 50%;
    z-index: 999;
    background-color: #fff;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
}

.location-picker input {
    padding: 20px 10px 20px 40px;
    border-radius: 0;
}

.location-picker:before {
    content: '\f3c5';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    padding: 0 15px;
    font-size: 18px;
    color: #6b6b83;
    pointer-events: none;
}

.location-picker.open {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.sorting-addressbox .btns {
    display: flex;
}

.cart-dropdown .cart-detail-box,
.notification-btn .notification-dropdown,
.user-dropdown {
    position: absolute;
    background: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
    border-radius: 3px;
    top: 104%;
    right: 0;
    width: 270px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.header .user-details:hover>.user-dropdown {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.user-dropdown ul {
    padding: 10px 15px;
    display: inline-table;
}

.user-dropdown ul>li {
    display: block;
    width: 50%;
    position: relative;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 0;
}


.header .user-details .user-dropdown ul>li>a {
    display: block;
    padding: 15px;
    border-radius: 3px;
}

.user-dropdown ul>li:hover a {
    background-color: #f3f3f3;
}

.user-dropdown ul>li>a .icon {
    display: block;
    margin: 0 auto 10px;
    text-align: center;
}

.user-dropdown ul>li>a .icon i {
    font-size: 30px;
    line-height: 1;
    font-weight: 400;
}

.user-details .user-dropdown ul>li>a span.details {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding-right: 0;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    font-weight: 500;
}

.user-dropdown .user-footer {
    display: flex;
    position: relative;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, .1);
    font-weight: 500;
}

.user-dropdown .user-footer span {
    margin-right: 8px;
}

.parent-megamenu,
.header .right-side .cart-btn.cart-dropdown,
.header .right-side .cart-btn.notification-btn {
    position: static;
}

.cart-dropdown:hover>.cart-detail-box,
.notification-btn:hover>.notification-dropdown {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.notification-dropdown .product-detail a {
    display: flex;
    padding: 20px;
    background: #f5f5f5;
    align-items: center;
}

.notification-dropdown .product-detail a .img-box {
    margin-right: 10px;
}

.notification-dropdown .product-detail a .product-about p,
.notification-dropdown .product-detail a .product-about h6 {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.notification-dropdown .product-detail a .product-about h6 {
    font-size: 14px;
    font-weight: 600;
}

.rating-module {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.rating-module span {
    transition: .3s;
    cursor: pointer;
}

.rating-module span:hover,
.rating-module span:hover~span {
    color: orange;
}

.notification-dropdown .rating-box {
    padding: 20px;
    text-align: center;
}

.notification-dropdown .rating-box cite {
    display: block;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
}

.cart-dropdown .cart-detail-box .card {
    border: none;
}

.cart-dropdown .cart-detail-box .card .card-header,
.cart-dropdown .cart-detail-box .card .card-footer {
    background: #fff;
    font-weight: 700;
    color: #3c3e41;
}

.cart-dropdown .cart-detail-box .card .card-body .item-total .total-price,
.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .cat-name {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .cat-name a p {
    margin-bottom: 0px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}

.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .cat-name a p span {
    margin-right: 10px;
}

.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .delete-btn i {
    font-size: 12px;
}

.parent-megamenu>a>i,
.mobile-search {
    display: none;
    padding: 0;
}

.header .mobile-address .delivery-add {
    align-items: center;
    justify-content: center;
    background: #fff;
}

.sorting-addressbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f4f4f4;
    padding: 15px;
}

.sorting-addressbox .btns .filter-btn {
    margin-left: 15px;
}

.sorting-addressbox .btns .filter-btn button {
    display: block;
    text-align: center;
    margin: 0 auto;
}

#search-box .modal-header .search-box {
    display: flex;
    align-items: center;
}

.header .main-search>.row>div {
    align-self: center;
}

#search-box .modal-header .search-box input {
    padding: 10px 20px;
    border-radius: 0;
}

/*megamenu style 1*/
.parent-megamenu .megamenu {
    position: absolute;
    top: 104%;
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
    border-radius: 3px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

.parent-megamenu:hover>.megamenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.parent-megamenu .megamenu>div {
    padding: 50px 75px 0;
    margin-left: 0;
    margin-right: 0;
    background-size: cover;
}

.parent-megamenu .megamenu>div:last-child {
    padding-top: 0;
}

.parent-megamenu .megamenu .menu-style {
    padding-bottom: 50px;
    white-space: nowrap;
}

.parent-megamenu .megamenu .menu-style .menu-title .cat-name {
    padding-bottom: 16px;
    margin-bottom: 11px;
    letter-spacing: .1em;
    text-transform: uppercase;
    position: relative;
}

.parent-megamenu .megamenu .menu-style .menu-title .cat-name:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #268968;
    width: 42px;
    height: 2px;
    margin: 0 auto;
}

.parent-megamenu .megamenu .menu-style ul li {
    line-height: 36px;
    text-transform: capitalize;
}

.parent-megamenu .megamenu .menu-style ul li a {
    position: relative;
    display: inline-block;
    font-size: 14px;
}

.parent-megamenu .megamenu .menu-style ul li a .badge {
    margin-left: 5px;
}

/*Categories*/
.browse-cat .categories {
    text-align: center;
    display: block;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    transition: .3s;
    margin-bottom: 30px;
}

.browse-cat .categories:hover {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.browse-cat .categories .icon {
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* border-radius: 50%; */
    width: 125px;
    height: 125px;
}

.browse-cat .categories .icon {
    position: relative;
    overflow: hidden;
}

.browse-cat .categories .icon img {
    transition: all 0.5s;
}

.browse-cat .categories .icon2 {
    width: 100%;
    height: 100%;
}

.browse-cat .categories .icon i {
    font-size: 45px;
}

.browse-cat .categories .icon img {
    max-width: 150px;
    margin: 0 auto;
}

.browse-cat .categories .cat-name {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #3c3e41;
}

.browse-cat-2 .categories .icon {
    position: relative;
    overflow: visible;
}

.browse-cat-2 .categories .icon img {
    border-radius: 0;
}

.browse-cat-2 .categories .icon .product-addbtn {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.browse-cat-2 .categories .icon .product-addbtn span i {
    font-size: 12px;
}

.banner-bottom-sec .info-box .info-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;
}

.banner-bottom-sec .info-box .info-box-wrapper .icon-box {
    transition: .3s;
}

.banner-bottom-sec .info-box:hover .info-box-wrapper .icon-box {
    background-color: #268968;
    color: #f6f4f0 !important;
}

.banner-bottom-sec .info-box:hover .info-box-wrapper .icon-box.text-white {
    background-color: #fff;
    color: #268968 !important;
}




.banner-bottom-sec .info-box .info-box-wrapper .icon-box {
    width: 80px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #268968;
    font-size: 35px;
}

.banner-bottom-sec .info-box .info-box-wrapper .text-box h6 {
    margin-bottom: 10px;
}

.about-farm .video-box {
    margin-bottom: 0;
}

.about-farm .video_wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #3c3e41;
    height: 400px;
}

.about-farm .videoIframe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}

.about-farm .videoPoster img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: none;
    background-position: center;
    overflow: hidden;
    opacity: 1;
    transition: opacity 100ms, height 0s;
    transition-delay: 0s, 0s;
}

.about-farm .videoPoster:hover {
    cursor: pointer;
}

.about-farm .videoPoster .video-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.about-farm .videoWrapperActive .videoPoster {
    opacity: 0;
    height: 0;
    transition-delay: 0s, 800ms;
}

.about-farm .video-btn-wrapper .promo-video-outer .video-button-stroke {
    color: #ffffff;
    opacity: 1;
}

.about-farm .video-btn-wrapper>a>i {
    font-weight: 600;
    color: #268968;
    font-size: inherit;
}


.about-farm .video-btn-wrapper .video-btn {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: inherit;
    font-size: 30px;
}

.about-farm .bottom-icons .icon-box-wrapper {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    max-width: 250px;
}

.about-farm .bottom-icons .icon-box-wrapper .icon-box {
    margin-bottom: 20px;
    color: #268968;
    font-size: 70px;
    line-height: 1;
}

.about-farm .bottom-icons .icon-box-wrapper h6 {
    white-space: nowrap;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}

.sale-products .product-item .img {
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    width: 150px;
}

.sale-products .product-item .img a.custom-svg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    opacity: 0;
    transition: .3s;
}

.sale-products .product-item:hover .img a.custom-svg {
    opacity: 1;
    visibility: visible;
    top: 50%;
}

.sale-products .product-item .img span a {
    color: #fff;
}

.sale-products .product-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
}

.sale-products .product-item h5 {
    margin-bottom: 10px;
    font-size: 16px;
}

.sale-products .product-item p {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
}

.sale-products .product-item .price span {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
}
.sale-products .product-item .price span+span{
    text-decoration: line-through;
    color: #c1c1c1;
    margin-left: 5px;
    font-size: 12px;
    display: inline-block;
}
.sale-products .product-item span.save-price {
    font-weight: 600;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 10px;
    color: #ec59ad;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: #ffeff8;
}

.sale-products .product-item p .line-through {
    font-weight: 400;
    text-decoration: line-through;
}

.testimonials-sec .testimonials-inner {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 4px;
    padding: 30px 50px;
    position: relative;
}

.testimonials-sec .testimonials-inner:before {
    content: '';
    background: #268968;
    height: 100%;
    width: 250px;
    position: absolute;
    left: 0;
}

.testimonials-sec .testimonials-inner .testimonials-img {
    width: 300px;
    height: 100%;
    margin-right: 50px;
}

.testimonials-sec .testimonials-inner .testimonials-text {
    width: calc(100% - 400px);
}

.testimonials-sec .testimonials-inner .testimonials-text h5 {
    font-size: 24px;
    margin-bottom: 10px;
}


.testimonials-sec .testimonials-inner .testimonials-text .testi-text:after {
    content: "\f10e";
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #268968;
    font-size: 18px;
    top: 40px;
    right: 40px;
    font-size: 120px;
    opacity: .1;
    line-height: 1;
}

/*Recent Order*/
.product-box-3,
.product-box {
    position: relative;
    border-radius: 3px;
    transition: .3s;
    background: #fff;
}

.local-deals .product-box-2 .product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.local-deals .product-box-2 .product-caption h6,
.local-deals .product-box-2 .product-caption .product-details {
    margin-bottom: 0;
    justify-content: space-between;
}

.local-deals .product-box-2 .product-caption .product-details span {
    font-size: 13px;
}


.product-box-3:hover,
.product-box:hover {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.product-box-3 .product-img,
.product-box .product-img {
    position: relative;
    overflow: hidden;
}

.product-box-3 .product-img img,
.product-box .product-img img {
    transition: 0.5s all;
}

.product-box-3:hover .product-img a:first-child img,
.product-box:hover .product-img a:first-child img {
    transform: scale(1.2);
}

.product-box .product-img {
    height: 200px;
}

.product-strain {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #f9f9f9;
}

.product-strain.indica {
    background-color: #72B241;
}

.product-strain.hybrid {
    background-color: #91509A;
}

.product-strain.sativa {
    background-color: #EB6223;
}

.product-box-3 .product-strain,
.product-box .product-strain {
    position: absolute;
    top: 20px;
    right: 20px;
}

.product-box .product-img img {
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.product-box-3 .product-caption,
.product-box .product-caption {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 20px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: 0;
}

.product-box-3 .product-caption .price,
.product-box .product-caption .price {
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 7px;
}
.product-box .product-caption .price span{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 7px;
}
.product-box .product-caption .price span+span{
    text-decoration: line-through;
    color: #c1c1c1;
    margin-left: 5px;
    font-size: 12px;
    display: inline-block;
}
.product-box-3 span.rectangle-tag,
.product-box span.rectangle-tag {
    font-weight: 600;
    display: inline-block;
    padding: 3px 6px;
    height: auto;
    border-radius: 3px;
    font-size: 10px;
    letter-spacing: 0;
    color: #ec59ad;
    background-color: #ffeff8;
}

.product-box .product-caption .title-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.product-box .product-caption .title-box .strain-box {
    border-radius: 3px;
    border: 1.5px solid #268968;
    font-size: 13px;
    color: #268968;
    font-weight: 600;
    line-height: 1;
    padding: 5px 8px 4px;
    margin-right: 8px;
}

.product-box .product-caption .title-box .strain-box.border-purple {
    color: #3c3e41;
    border-color: #3c3e41;
}

.product-box .product-caption .product-title h6 {
    margin-bottom: 0;
}

.product-box .product-caption .product-title h6 a {
    margin-bottom: 10px;
    font-size: 20px;
    display: block;
    color: #3c3e41;
    font-weight: 700;
}

.product-box .product-caption .product-title h6 a:hover {
    color: #268968;
}

.product-box .product-caption .product-title .product-price {
    margin-bottom: 10px;
    display: block;
    line-height: 1.2;
}

.product-caption .product-title .product-price ins {
    color: #268968;
    font-size: 18px;
    font-weight: 500;
    margin-right: 5px;
    text-decoration: none;
}

.product-caption .product-title .product-price del {
    color: #909296;
    font-size: 12px;
    text-decoration: line-through;
}

.product-box .product-caption p {
    font-size: 12px;
}

.product-box .product-caption .product-btn {
    margin-top: 10px;
}

.product-box .product-img .product-tags .custom-tag,
.product-box .product-img .product-tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.product-box .product-img .product-tags>span:first-child {
    position: absolute;
    top: 10px;
    right: 10px;
}

.product-box .product-caption .product-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}

.product-box .product-caption .product-details .product-reviews {
    margin-left: 8px;
}

.product-caption .product-details .product-reviews a {
    text-decoration: underline;
    display: block;
}

.product-box .product-caption .product-details span {
    display: block;
    line-height: normal;
    font-size: 15px;
}

.product-box .product-caption .product-footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.product-box .product-footer-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f1f1;
    padding: 12px 10px;
}

/*featured-product*/
.featured-product {
    position: relative;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    margin: 3px;
}

.featured-product:hover {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .2), 0 1px 5px 0 rgba(67, 41, 163, .2);
}

.featured-product .featured-product-details {
    padding: 15px;
    display: flex;
}

.featured-product .featured-product-details .pro-logo {
    width: 64px;
    height: 64px;
    overflow: hidden;
    flex-shrink: 0;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(66, 41, 163, .08), 0 0 0 1px rgba(66, 41, 163, .08);
    margin-right: 15px;
}

.featured-product .featured-product-details .pro-title {
    max-width: 200px;
}

/*product-box-3*/
.product-box-3 .product-caption ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.product-box-3 .product-caption ul.food-type li {
    padding: 0 7px;
    position: relative;
    margin-bottom: 10px;
}

.product-box-3 .product-caption ul.food-type li:after {
    content: '';
    background: #6b6b83;
    width: 3px;
    height: 3px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
}

.product-box-3 .product-caption ul.food-type li:first-child {
    padding-left: 0;
}

.product-box-3 .product-caption ul.food-type li:last-child:after {
    display: none;
}

.product-box-3 .product-caption ul.food-description li {
    background-color: #F6F6F6;
    padding: 2px 8px;
    font-size: 12px;
    margin-right: 8px;
}

/*Explore Collection*/
.ex-collection-box {
    position: relative;
    overflow: hidden;
}
.ex-collection-box .ex-collection-box-text button{
    cursor: context-menu;
}
.ex-collection-box img:not(.mood) {
    transition: 0.5s;
}

.ex-collection-box:hover img:not(.mood) {
    transform: scale(1.1);
}

.ex-collection-box img.mood {
    position: absolute;
    top: 20px;
    right: 20px;
}

.ex-collection-box:hover img.mood {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}


.ex-collection-box .ex-collection-box-text {
    max-width: 400px;
    margin: 0 auto;
}
.ex-collection-box .ex-collection-box-text h4{
  margin-bottom: 15px;
}

.ex-collection .category-type a {
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}

.large-product-box {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 3px;
    right: 15px;
    left: 15px;
}

.large-product-box img {
    height: 100%;
    position: relative;
    object-position: center;
    object-fit: cover;
}

.large-product-box .content-on-image {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 100%;
    border-top: 20px solid #268968;
    border-right: 20px solid #268968;
    border-bottom: 20px solid #268968;
    border-left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.large-product-box .content-on-image.content-on-image2 {
    border-left: 20px solid #268968;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    left: 0px;
    text-align: right;
}

.large-product-box .content-on-image .animated-content {
    padding: 20px;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.large-product-box .content-on-image .animated-content .trusted-product {
    color: #fff;
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
}

.large-product-box .content-on-image .animated-content .product-name h4 {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 8px;
}

.large-product-box .content-on-image .animated-content .product-name h6 {
    color: #268968;
    font-size: 16px;
    margin-bottom: 0;

}

.large-product-box .content-on-image .animated-content .discounted-price {
    margin: 10px 0;
    color: #fff;
    font-size: 18px;
    display: block;
    font-weight: 600;
}

.large-product-box .category-type a {
    left: 15px;
    width: auto;
    right: 15px;
    margin-bottom: 15px;
}

.featured-products-sec {
    background-image: url(../img/image_left-1.png), url(../img/image_right-1.png);
    background-repeat: no-repeat;
    background-position: left top, right top;
    background-size: auto 100%;
    background-color: #f6f4f0;
}
.featured-products-sec .product-thumb-img{
    height: 100%;
}
.featured-products-sec .product-thumb-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.featured-products-sec .product-thumb-text {
    position: relative;
}

.featured-products-sec .product-thumb-text .circle-tag {
    position: absolute;
    top: 0;
    right: 0;
}

.featured-products-sec .product-thumb-text .product-thumb-btn,
.featured-products-sec .product-thumb-text .price {
    display: flex;
}

.featured-products-sec .product-thumb-text .price del {
    display: flex;
    align-items: flex-end;
    font-size: 16px;
}

.featured-products-sec .product-thumb-text .price ins {
    font-size: 20px;
    margin: 0 10px;
}

.featured-products-sec .product-thumb-text .price,
.featured-products-sec .product-thumb-text h4 {
    margin-bottom: 10px;
}
.featured-products-sec .product-thumb-text h4{
  font-size: 30px;
}

.featured-products-sec .product-thumb-text .price {
    font-size: 24px;
    font-weight: 600;
    color: #268968;
    display: block;
    margin-bottom: 7px;
}
.featured-products-sec .product-thumb-text .price span+span{
    text-decoration: line-through;
    color: #c1c1c1;
    margin-left: 10px;
    font-size: 12px;
    display: inline-block;
}
/*.featured-products-sec .product-thumb-text .circle-tag{
    margin-left: 8px;
}*/
.featured-products-sec .product-thumb-text .product-thumb-btn button {
    margin-right: 15px;
}

.featured-products-sec .product-thumb-text .shop-bottom .shop-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.featured-products-sec .product-thumb-text .shop-bottom .shop-meta+.shop-meta {
    margin-top: 10px;
}

.featured-products-sec .product-thumb-text .shop-bottom .shop-meta span {
    margin-right: 5px;
}

.featured-products-sec .product-thumb-text .shop-bottom .shop-meta a {
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: 12px;
    color: #6b6b83;
    padding: 3px 6px;
}

.featured-products-sec .product-thumb-text .shop-bottom .shop-meta a:hover {
    background-color: #268968;
    color: #fff;
}

/*Page Banner*/
.banner-1 {
    height: 700px;
    width: 100%;
}

.banner-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.banner-1 .content-wrapper {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
    border-radius: 3px;
}

.banner-1 .content-wrapper .content-box .input-group {
    margin: 0 -8px;
}

.banner-1 .content-wrapper .content-box .input-group-prepend {
    height: 48px;
    min-width: 44px;
    flex-basis: 44px;
    border: 1px solid #8f8fa1;
    border-left: 0;
    border-radius: 0px 4px 4px 0;
    display: none;
}

.banner-1 .content-wrapper .content-box .input-group-prepend .input-group-text {
    line-height: 1.5;
    font-size: 20px;
    background: transparent;
    border: none;
}

.banner-1 .content-wrapper .content-box .input-group .input-group2 {
    display: flex;
}

.banner-1 .content-wrapper .content-box .input-group .input-group2,
.banner-1 .content-wrapper .content-box .input-group-append {
    padding: 0 8px;
}

.banner-1 .right-side-content h1 {
    font-size: 60px;
    line-height: 1.2;
}

/*Advertisement Slider*/
.advertisement-slider {
    height: 100%;
}

.advertisement-slider .swiper-wrapper .swiper-slide .large-product-box {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
}

.advertisement-slider .swiper-wrapper .swiper-slide .large-product-box .content-wrapper {
    z-index: 1;
    position: absolute;
    bottom: 20px;
}

.advertisement-slider .swiper-wrapper .swiper-slide .large-product-box .tag-box {
    position: absolute;
    top: 20px;
    z-index: 1;
}

/*Blog Section*/

.our-blog .blog-box .post .blog-img .blog-meta {
    position: absolute;
    bottom: 0;
    padding: 20px;
    z-index: 1;
}
.our-blog .blog-box .post ,
.our-blog .blog-box .post .blog-img {
    height: calc(100% - 28px);
}
.our-blog .blog-box .post .blog-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.our-blog .blog-box .post .blog-img .blog-meta h4 a {
    color: #fff;
}

.our-blog .blog-box .post .blog-img .blog-meta h4 a:hover {
    color: #268968;
}

.our-blog .blog-box .side-post {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;
    transition: .3s;
}
.our-blog .blog-box .side-post .entry-tye{
  margin-bottom: 5px;
}

.our-blog .blog-box .side-post:hover {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.our-blog .blog-box .side-post .thumb-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 100px;
    max-width: 100px;
}

.our-blog .blog-box .side-post+.side-post {
    margin-top: 30px;
}

/*footer*/
footer {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

footer .ft-logo-box {
    display: flex;
    align-items: center;
}

footer .ft-logo-box .ft-logo {
    margin-right: 25px;
}

footer .ft-social-media {
    display: block;
    align-items: center;
    height: 100%;
    padding-top: 80px;
}

footer .ft-social-media ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

footer .ft-social-media ul li {

    margin: 0 15px;
    width: calc(20% - 30px);
    position: relative;
    overflow: hidden;
    height: 150px;
    padding: 25px;
    text-align: right;
    transition: all .2s ease;
    margin-bottom: 30px;
}

footer .ft-social-media ul li.facebook .bg-social-block {
    background-color: #3b5998;
}

footer .ft-social-media ul li.twitter .bg-social-block {
    background-color: #5ea9dd;
}

footer .ft-social-media ul li.google-plus .bg-social-block {
    background-color: #df4b38;
}

footer .ft-social-media ul li.instagram .bg-social-block {
    background-color: #8a3ab9;
}

footer .ft-social-media ul li.pintrest .bg-social-block {
    background-color: #BD081C;
}

footer .ft-social-media ul li .bg-social-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

footer .ft-social-media ul li h4 {
    color: #fff;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    z-index: 1;
    right: 25px;
    bottom: 20px;
    transition: all .2s ease-out;
}

footer .ft-social-media ul li .social-count {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    right: 25px;
    bottom: 40px;
    display: block;
    opacity: 0;
    visibility: hidden;
    color: #fff;
}

footer .ft-social-media ul li .social-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    right: 25px;
    bottom: 20px;
    display: block;
    opacity: 0;
    visibility: hidden;
    color: #fff;
}

footer .ft-social-media ul li i {
    position: absolute;
    z-index: 1;
    font-size: 80px;
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    line-height: 140px;
    left: -21px;
    bottom: -30px;
    text-align: center;
    opacity: .5;
    transition: all .2s ease-out;
    color: #fff;
}

footer .ft-social-media ul li:hover {
    box-shadow: 0 6px 22px 4px rgba(0, 0, 0, .4);
    z-index: 1;
    border-color: transparent;
}

footer .ft-social-media ul li:hover h4 {
    bottom: 70px;
}

footer .ft-social-media ul li:hover .social-text,
footer .ft-social-media ul li:hover .social-count {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    z-index: 1;
}

footer .ft-social-media ul li:hover i {
    opacity: 1;
}

footer .ft-social-media ul li a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ada3a3;
    transition: 0.3s;
    text-decoration: none;
}

footer .ft-social-media ul li a i {
    font-size: 18px;
}

footer .ft-social-media ul li:first-child {
    margin-left: 0;
}

.footer-buttons a {
    display: inline-flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    border: 0;
    color: #3c3e41;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 30px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-transform: uppercase;
    border-radius: 4px;
    z-index: 1;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
}

footer .footer-buttons a+a {
    margin-left: 15px;
}

footer .footer-buttons a:hover {
    text-decoration: none;
    color: #3c3e41;
    background-color: #f9f9f9;
}

.footer-buttons a svg {
    margin-right: 10px;
    display: block;
    width: 20px;
    height: auto;
    flex-shrink: 0;
    fill: #e4821b;
}

.copyright .medewithlove {
    overflow: hidden;
}

.copyright .medewithlove a:hover {
    text-decoration: underline;
}

.copyright .madewithlove a {
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.copyright .medewithlove a:hover i {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.copyright .medewithlove a i {
    color: red;
}

.hm-list.hm-instagram {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.hm-list.hm-instagram li {
    overflow: hidden;
}

.hm-list.hm-instagram li a {
    position: relative;
    display: block;
}

.hm-list.hm-instagram li a:before {
    content: '';
    background: #ffcd34;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .3s;
    z-index: 1;
}

.hm-list.hm-instagram li a:after {
    font-family: 'Font Awesome 5 Brands';
    content: "\f16d";
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: .3s;
    color: #fff;
    opacity: 0;
    z-index: 1;
}

.hm-list.hm-instagram li:hover a:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.hm-list.hm-instagram li:hover a:before {
    opacity: 0.4;
}

.hm-list.hm-instagram li {
    position: relative;
    margin-right: 0;
}

.hm-list li {
    display: inline-block;
    margin-right: 15px;
}

.hm-list.hm-instagram li img {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 0px;
    width: 100%;
}

.hm-list.hm-instagram li:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

footer .subscribe_form .input-group {
    padding: 6px;
    background-color: #fff;
    border-radius: 3px;
}

footer .subscribe_form .input-group input {
    border: 0;
}

footer .subscribe_form .input-group button i {
    margin-right: 10px;
}


footer .ft-social-media ul li:hover {
    border-color: #268968;
    transform: translateY(-5px);
    transition: 0.3s;
}

footer .ft-social-media ul li:hover a {
    color: #268968;
    transform: rotate(25deg);
    transition: 0.3s;
}

footer .ft-logo-box {
    padding-bottom: 80px;
}

footer .footer-links,
footer .footer-contact {
    padding-top: 80px;
}

footer .footer-links ul,
footer .footer-contact ul {
    margin-bottom: 10px;
}

footer .footer-contact li {
    margin-bottom: 10px;
}

footer .footer-contact li a {
    text-decoration: underline;
}

footer .footer-links ul li {
    margin-bottom: 5px;
}

footer .footer-links .appimg {
    margin-bottom: 10px;
}

footer h6 {
    position: relative;
    margin-bottom: 20px;
    font-size: 24px;
}

/*footer-top*/
.footer-top .icon-box {
    position: relative;
    text-align: center;
    word-break: break-all;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    transition: .3s;
    overflow: hidden;
}

.footer-top .icon-box .icon-overlay {
    position: absolute;
    top: -40px;
    right: -40px;
    font-size: 120px;
    line-height: 1;
    opacity: .1;
}

.footer-top .icon-box:hover {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.footer-top .icon-box h5 {
    margin: 0 auto;
    font-size: 18px;
    max-width: 150px;
}

.footer-top .icon-box span i {
    font-size: 50px;
    margin-bottom: 10px;
    line-height: 1;
}

.footer-top .icon-box span {
    display: block;
    margin-bottom: 20px;
}

/*copyright*/
.copyright {
    padding: 20px 0;
}

.copyright .payment-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.copyright .copyright-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

.copyright .copyright-text a:hover {
    text-decoration: underline;
}

/*======================
3. Blog Grid
========================*/
.breadcrumb .breadcrumb-item {
    margin: 0;
}

.breadcrumb {
    margin: -20px;
    background-color: #fff;
    padding: 1.5rem 20px;
    border-bottom: 1px solid #4329a333;
    border-radius: 0;
    margin-bottom: 20px;
}

.breadcrumb .breadcrumb-item a {
    color: #131313;
    font-weight: 600;
}

.breadcrumb-item.active {
    color: #268968;
}

.our-articles .blog-page-banner {
    position: relative;
    background-image: url('../img/banner/banner-5.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(280px * 2);
    background-attachment: fixed;
}

.our-articles .blog-page-banner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .6), transparent);
}

.our-articles .side-bar h5 {
    font-size: 18px;
}

.our-articles .blog-inner {
    position: relative;
    display: flex;
}

.side-bar .main-box {
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: none;
}

.side-bar .main-box h5 {
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.side-bar .main-box h5::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: #268968;
}

.side-blog .side-post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.side-blog .side-post:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.side-blog .content-wrap {
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
}
.side-blog .content-wrap .entry-tye{
  margin-bottom: 5px;
}

.side-blog .thumb-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 100px;
    max-width: 100px;
    margin-right: 15px;
}

.side-bar .blog-tags a {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px 10px 0;
    background: #f9f9f9;
    font-size: 12px;
    text-transform: uppercase;
    color: #6b6b83;
    border: 1px solid rgba(0, 0, 0, .1);
    font-weight: 600;
}

.side-bar .blog-tags a:hover {
    background-color: #268968;
    color: #fff;
}

.side-bar .trending-blog-cat li {
    display: flex;
    position: relative;
}

.side-bar .trending-blog-cat li:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.side-bar .trending-blog-cat li a {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    text-transform: capitalize;
    justify-content: space-between;
}

.side-bar .large-product-box {
    position: relative;
    height: auto;
    padding-bottom: 0;
    right: 0;
    left: 0;
}

.side-bar .large-product-box .category-type a {
    bottom: 0;
    position: absolute;
    margin-bottom: 15px;
}

.side-bar .main-padding {
    padding: 15px;
}

.side-bar .inner-wrapper {
    z-index: 1;
}

.side-bar .main-banner,
.side-bar .section-2 {
    height: auto;
}

.side-bar .main-padding img {
    width: 110px;
}

.side-bar .main-banner .footer-img {
    max-width: 200px;
}

.side-bar .inner-wrapper .main-page .login-box {
    display: block;
}

.inner-wrapper .main-page .login-box .input-group {
    max-width: 600px;
}

.our-articles .blog-services-wrapper {
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 0;
}

.our-articles .blog-services-wrapper .post-meta {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
}

.our-articles .blog-services-wrapper .author-img {
    width: 40px;
}

.our-articles .blog-services-wrapper .author-meta {
    margin-left: 10px;
}
.our-articles .blog-services-wrapper .author-meta h6{
  margin-bottom: 3px;
}
.our-articles .blog-services-wrapper .post-content h2 {
    font-size: 18px;
}

.our-articles .blog-services-wrapper .post-content .blog-link-wrap {
    margin-top: 20px;
}

.our-articles .blog-services-wrapper .post-img a {
    display: block;
    overflow: hidden;
}

.our-articles .blog-services-wrapper .post-img a img {
    transition: .3s;
}

.our-articles .blog-services-wrapper .post-img a:hover img {
    transform: scale(1.1);
}

.our-articles .comment-box,
.our-articles .comment-form {
    padding: 40px 40px 10px;
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;
}

.our-articles .comment-form {
    padding: 40px;
}

.our-articles .comment-box h3,
.our-articles .comment-form h3 {
    margin-bottom: 40px;
}

.entry-content img,
.entry-content p {
    margin-bottom: 20px;
}

.entry-content p:last-child {
    margin-bottom: 0;
}

/*======================
4. Ex Deals
========================*/
.exclusive-deals .deals-heading {
    padding: 15px 0;
}

.exclusive-deals .deals-image {
    display: flex;
    align-self: center;
    align-items: center;
}

/*product-box-2*/
.product-box-2 {
    display: flex;
}

.product-box-2 .product-img {
    width: 120px;
    height: 112px;
}

.product-box-2 .product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.local-deals .btn-first {
    margin: 20px auto 0px;
}

/*======================
5. Restaurent
========================*/
.page-banner {
    height: 270px;
}

.page-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.page-banner>.overlay-2>.container>.row {
    padding-top: 10px;
}

.tag-share {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/*Restaurent Details*/

.heading .title {
    margin-bottom: 5px;
}

.heading .sub-title span {
    margin-left: 20px;
}

.restaurent-logo {
    position: absolute;
    top: -75px;
    right: 15px;
    border-radius: 50%;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    background: #fff;
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading .head-rating .rating span.rate-data {
    margin-left: 5px;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.heading .head-rating,
.heading .head-rating .product-review {
    display: flex;
    align-items: center;
}

.heading .head-rating .product-review {
    margin-left: 35px;
}

.heading .head-rating .product-review h6 {
    margin-right: 25px;
}

.heading .head-rating .product-review h6 span {
    font-weight: 400;
}

.restaurent-details-mob {
    display: none;
    text-align: center;
}

.restaurent-details-mob span {
    display: block;
    font-weight: 600;
}

/*Restaurent tabs*/

.restaurent-tabs.fix-tab {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 2;
}

.restaurent-tabs .restaurent-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.restaurent-tabs .nav-pills .nav-link {
    margin-right: 15px;
    margin-bottom: 0;
    padding: 20px 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
}

.restaurent-tabs .nav-pills .nav-link.active {
    border-bottom: 3px solid #268968;
    color: #3c3e41;
    opacity: 1;
    background-color: transparent;
}

.restaurent-tabs .restaurent-menu .add-wishlist img {
    width: 20px;
    cursor: pointer;
}

/*promocode*/

.promocodeimg {
    height: 115px;
}

.promocodeimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.promocode-text {
    position: absolute;
    top: 0;
    display: flex;
    right: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    flex-wrap: wrap;
    z-index: 1;
}

.promocode-text .promocode-text-content h5,
.promocode-text .promocode-text-content p {
    word-break: break-all;
}

.promocode-btn a {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 3px;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 600;
}

/*Restaurent Address*/

.restaurent-address {
    padding: 20px 0;
}

.restaurent-address .address-details .address,
.restaurent-address .address-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.restaurent-address .address-details .address {
    flex-basis: auto;
    flex-grow: 0;
    max-width: 100%;
}

.restaurent-address .address-details .address .delivery-address {
    padding-right: 40px;
}

.restaurent-address .address-details .change-address {
    display: flex;
    flex-direction: column;
    padding: 0 5px 0 15px;
    text-align: right;
    justify-content: center;
}

/*restaurent-ad*/
.restaurent-ad .ad-img {
    height: 350px;
}

.restaurent-ad .ad-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.restaurent-ad .ad-img .content-box {
    padding: 0 60px;
}

/*Restaurent Meals*/
.restaurent-meals {
    position: relative;
}

.restaurent-meals .restaurent-meal-head>.card {
    padding: 0;
    border: none;
    background: transparent;
}

.restaurent-meals .restaurent-meal-head>.card>.card-header {
    border: none;
    padding: .75rem 0 0;
    background: transparent;
}

.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link:after {
    content: '\f077';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 12px;
    margin-left: 10px;
    transition: .3s;
}

.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link.collapsed:after {
    transform: rotate(180deg);
}

.restaurent-meals .restaurent-meal-head>.card>.collapse>.card-body {
    padding: 0;
}

.restaurent-product-list {
    position: relative;
    border-radius: 3px;
    background-color: #fff;
    cursor: text;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid rgba(0, 0, 0, .1);
    transition: .3s;
}

.restaurent-product-list .restaurent-product-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.restaurent-product-list:hover {
    box-shadow: 0 4px 24px rgba(0, 0, 0, .04);
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left {
    padding: 0 20px 0 0;
    width: 100%;
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-tags-price,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-caption-box,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-title-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box .restaurent-product-label,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box .restaurent-product-title {
    margin-right: 15px;
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-caption-box,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box .restaurent-product-label {
    align-self: flex-start;
    margin-bottom: 10px;
}

.restaurent-product-list .restaurent-product-detail span {
    word-break: break-all;
}

.restaurent-product-list .restaurent-product-detail .restaurent-tags {
    display: flex;
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-tags-price {
    align-items: center;
}

.restaurent-product-list .restaurent-product-rating {
    text-align: center;
    padding: 0 10px;
    padding-left: 0;
}

.restaurent-product-list .restaurent-product-rating .ratings {
    display: flex;
}

.restaurent-product-list .restaurent-product-rating .rating-text p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.restaurent-product-list .restaurent-product-img {
    width: 150px;
    flex-basis: 150px;
    line-height: 0;
    height: 150px;
}

.restaurent-product-list .restaurent-product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-tags-price .circle-tag {
    position: absolute;
    top: 12px;
    right: 12px;
}

.sidebar-card .offer-content {
    text-align: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 20px;
    position: relative;
    min-height: 100%;
    background: linear-gradient(0deg, #6a1d89a8, #6a1d89a8), url('../img/offer.jpg');
    background-size: cover;
}

.sidebar-card .offer-content h2 {
    text-transform: uppercase;
    font-size: 50px;
}

.sidebar-card .offer-content h2 small {
    display: block;
    font-size: 30px;
    text-transform: lowercase;
}

/*Restaurent About*/

.restaurent-about .about-restaurent {
    margin-top: 10px;
}

.restaurent-about .about-restaurent li {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: flex-start;
}

.restaurent-about .about-restaurent li .custom-svg {
    width: 30px;
}

.restaurent-about .about-restaurent li .custom-svg path {
    fill: #6b6b83;
}

.restaurent-about .about-restaurent li i {
    font-size: 16px;
}

.restaurent-about .about-restaurent li span {
    position: relative;
}

.restaurent-schdule .card,
.restaurent-schdule .card .card-header {
    border: none;
    border-radius: 0;
}

.restaurent-schdule .card .card-body {
    padding: 20px 20px 10px;
}

.restaurent-schdule .card .card-body .schedule-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.restaurent-schdule .card .card-footer {
    background: #fff;
}

.social-media {
    display: flex;
    align-items: center;
}

.social-media li {
    width: 20%;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 0;
    border-right: 0;
    transition: 0.3s;
}

.social-media li:last-child {
    border: 1px solid rgba(0, 0, 0, .1);
}

.social-media li a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.social-media li:hover {
    background: #268968;
}

.social-media li:hover a {
    color: #fff;
}

/*Restaurent Review*/

.restaurent-review ul {
    display: flex;
    margin: 0 -15px 20px;
}

.restaurent-review ul li {
    padding: 0 15px;
}

.restaurent-review .review-img {
    max-width: 540px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0 0;
    padding-bottom: 0;
}

.restaurent-review .review-img img {
    margin-bottom: 30px;
}

.review-box:first-child {
    padding: 40px 0 20px;
}

.review-box {
    padding: 20px 0;
}

.review-box.comment-reply {
    margin-left: 60px;
}

.review-box .review-user {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.review-box .ratings,
.review-box .review-user {
    margin-bottom: 10px;
}

.review-box .food-order {
    margin-bottom: 5px;
    display: block;
}

.review-box .review-user .review-user-img {
    display: flex;
    align-items: center;
}

.review-box .review-user .review-user-img img {
    margin-right: 12px;
}

.review-box .review-user .review-user-img .reviewer-name p {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: normal;
}

.review-box .review-user .review-user-img .reviewer-name p small {
    margin-left: 10px;
    font-size: 12px;
}

.review-box .review-user .review-user-img .reviewer-name span {
    font-size: 12px;
    vertical-align: middle;
    margin-left: 3px;
}

.review-box .food {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0;
}

.review-box .food li {
    max-width: 100%;
    padding: 0;
}

.review-box .food li button.add-pro {
    cursor: pointer;
    margin-right: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, .05);
    text-transform: capitalize;
    padding: 3px 15px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    position: relative;
    padding-right: 35px;
    font-weight: 500;
    margin-bottom: 10px;
}

.review-box .food li button.add-pro span.close {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 22px;
    top: 50%;
    transform: translate(0px, -50%);
    text-align: right;
    padding: 0;
    color: #fff;
    font-weight: bold;
    opacity: 1;
}

.restaurent-popup .modal-content {
    min-height: 85vh;
    overflow-y: auto;
    border: 0;
}

.restaurent-popup .modal-header {
    position: relative;
    display: block;
    padding: 0;
    height: 200px;
}

.restaurent-popup .modal-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

.restaurent-popup .modal-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.restaurent-popup .close {
    margin: 0;
    display: block;
    width: 100%;
    text-align: right;
    color: #fff;
    opacity: 1;
    padding: 0;
    font-size: 35px;
    text-shadow: none;
    font-weight: 200;
    line-height: 1;
    margin-bottom: 20px;
    position: absolute;
    padding: 20px;
}

.restaurent-popup .modal-title {
    font-size: 20px;
}

.restaurent-popup .modal-body {
    padding: 20px;
    overflow: auto;
}

.restaurent-popup .price {
    font-size: 24px;
    font-weight: 600;
    color: #268968;
    display: block;
    margin-bottom: 7px;
}

.product-quantity {
    display: flex;
    align-items: center;
}

.product-quantity span {
    margin-right: 20px;
}

.product-quantity .quantity {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 19px;
    flex-grow: 0;
    overflow: hidden;
    position: relative;
    height: 38px;
    width: auto;
    margin: 20px 0 27px;
}

.product-quantity .quantity button {
    padding: 0 15px;
    color: rgba(0, 0, 0, .1);
    transition: .3s;
}

.product-quantity .quantity button:hover {
    background-color: #f9f9f9;
    color: #3c3e41;
}

.product-quantity .quantity button:focus {
    outline: none;
}

.product-quantity .quantity input {
    border: none;
    width: 40px;
}

/*additional-product*/
.additional-product {
    padding: 20px 0 0;
    max-height: 365px;
    overflow-y: auto;
}

.additional-product .card {
    border-radius: 0;
    border: none;
    margin-bottom: 10px;
}

.additional-product .card .card-header {
    border-radius: 0;
    background: transparent;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.additional-product .card .card-header .card-link {
    font-size: 18px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0;
}

.additional-product .card .card-header .card-link span {
    display: block;
}

.additional-product .card .card-header .card-link:after {
    content: '\f077';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 20px;
    transition: .3s;
}

.additional-product .card .card-header .card-link.collapsed:after {
    transform: rotate(180deg);
}

.additional-product .card .card-body button.add-pro {
    cursor: pointer;
    margin-right: 10px;
    color: rgba(0, 0, 0, .87);
    background: rgba(0, 0, 0, .05);
    text-transform: capitalize;
    padding: 3px 15px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    position: relative;
    padding-right: 35px;
    font-weight: 500;
    margin-bottom: 10px;
}

.additional-product .card .card-body button.add-pro:hover {
    background: #e8e8e8;
}

.additional-product .card .card-body button.add-pro span.close {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 22px;
    top: 50%;
    transform: translate(0px, -50%);
    text-align: right;
    padding: 0;
    color: #3c3e41;
    font-weight: bold;
}

/*map-gallery-se*/
.map-gallery-sec .main-box #locmap {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    z-index: 0;
    pointer-events: none;
    border: none;
}

.map-gallery-sec .map-pl-0 {
    padding-left: 0;
}

.map-gallery-sec .map-pr-0 {
    padding-right: 0;
}

.map-gallery-sec .gallery-box .gallery-img {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: -5px;
}

.map-gallery-sec .gallery-box .gallery-img li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 5px;
}

.smoothscroll.active {
    position: relative;
}

.smoothscroll.active#menu {
    padding-top: 0;
}

/*video box*/
.video-box iframe {
    width: 100%;
    border: none;
    height: 250px;
}

.video_wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #3c3e41;
    height: 250px;
}

.videoIframe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}

.videoPoster {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url('../img/video-banner.jpg');
    background-size: cover;
    border: none;
    background-position: center;
    overflow: hidden;
    opacity: 1;
    transition: opacity 100ms, height 0s;
    transition-delay: 0s, 0s;
}

.videoPoster .promo-video-outer {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.promo-video-outer .video-button-circle,
.videoPoster .promo-video-outer .video-button-stroke {
    color: #fff;
    opacity: 1;
}

.video_wrapper:hover .video-inner i {
    color: #268968;
    transition: 0.5s;
}

.videoPoster:hover {
    cursor: pointer;
}

.videoPoster .video-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.videoPoster .video-inner i {
    font-size: 70px;
    color: #fff;
}

.videoWrapperActive .videoPoster {
    opacity: 0;
    height: 0;
    transition-delay: 0s, 800ms;
}

.discount-box .discount-price .right-side,
.discount-box .discount-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discount-box .discount-price .right-side .dis-text {
    margin-right: 25px;
}

.discount-box .discount-price .right-side,
.discount-box .discount-price .left-side {
    max-width: 50%;
    flex-basis: 50%;
}

/*side bar cart*/
.sidebar .cart-detail-box {
    width: 100%;
    background: #fff;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    cursor: pointer;
}

.sidebar .cart-detail-box>.card {
    border: none;
}

.sidebar .cart-detail-box>.card .card-header,
.sidebar .cart-detail-box>.card .card-footer {
    background: #fff;
}

.sidebar .cart-detail-box>.card>.modify-order>.total-amount,
.sidebar .cart-detail-box>.card .card-body .item-total .total-price,
.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product .cat-name a p {
    margin-bottom: 0px;
}

.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product .cat-name a p span {
    margin-right: 10px;
}

.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product .cat-name {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar .cart-detail-box>.card>.modify-order>.total-amount {
    background-color: #8f8fa1;
}

/*======================
6. Add Restaurent
========================*/
/*add restaurent*/
.register-restaurent-sec {
    counter-reset: section;
}

#add-restaurent-tab .step-steps>li:after {
    counter-increment: section;
    content: counter(section);
    position: absolute;
    top: 16px;
    font-size: 10px;
    left: 0;
    right: 0;
    pointer-events: none;
    font-weight: 600;
}

#add-restaurent-tab .step-steps>li.go2998626915:after,
#add-restaurent-tab .step-steps>li.go2998626915:after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #fff;
    font-size: 7px;
    top: 20px;
}

#add-restaurent-tab .step-footer .none {
    display: none;
}

#add-restaurent-tab .step-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 30px;
}

#add-restaurent-tab .step-footer #next-1 {
    margin: 0px 0px 0px auto;
}

#add-restaurent-tab .steps-1 {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
}

.step-app>.steps-1 {
    overflow: hidden;
    margin: 0 auto;
}

#add-restaurent-tab .steps-1>li {
    flex: 0 0 25%;
    max-width: 25%;
}

.step-app .steps-1>li {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
}

#add-restaurent-tab .steps-1>li:before {
    background-color: #e3e3e3;
    height: 12px;
    left: 45%;
    top: 19px;
    width: 100%;
    transition: all 0.3s;
    margin-left: 4px;
}

#add-restaurent-tab .steps-1>li:first-child:before {
    border-radius: 10px 0 0 10px;
}

.step-app .steps-1>li:before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 2px;
    background-color: #C4CAD2;
    top: 35px;
    left: 85px;
}

.step-app .steps-1>li>a,
.step-app .steps-1>li>a>span {
    display: block;
}

#add-restaurent-tab .steps-1>li>a>.number {
    width: 20px;
    height: 20px;
    background-color: #e3e3e3;
    border: 0px solid;
    transition: all 0.3s;
}

.step-app .steps-1>li>a>.number {
    border-radius: 50%;
    color: #b6b6b6;
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
    margin: 10px auto;
    position: relative;
    z-index: 0;
    top: 5px;
}

#add-restaurent-tab .steps-1>li.go2998626915>a>.number,
#add-restaurent-tab .steps-1>li.go2998626915>a>.number {
    background-color: #8fde8f;
}

#add-restaurent-tab .steps-1>li>a>.step-name {
    font-weight: 600;
    color: #268968;
    font-size: 12px;
    margin-top: 20px;
    transition: all 0.3s;
}

#add-restaurent-tab .steps-1>li:last-child:before {
    width: 0;
}

#add-restaurent-tab .steps-1>li.go2998626915:before {
    background-color: #8fde8f;
}

#add-restaurent-tab .steps-2 {
    margin: 20px 0;
}

#add-restaurent-tab .steps-2>li {
    display: block;
    margin-bottom: 10px;
}

#add-restaurent-tab .steps-2>li:before,
#add-restaurent-tab .steps-2>li:after {
    display: none;
}

#add-restaurent-tab .steps-2>li>a.add-res-tab {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.595;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    border-color: rgba(0, 0, 0, .1);
    background: #fff;
    font-weight: 600;
}

#add-restaurent-tab .steps-2>li.go2998626915 a.add-res-tab,
#add-restaurent-tab .steps-2>li.go2998626915 a.add-res-tab {
    color: #fff;
    background: #268968;
    border-color: #268968;
}

#add-restaurent-tab.step-app .step-content .step-tab-panel {
    display: none;
}

#add-restaurent-tab.step-app .step-content .step-tab-panel.active {
    display: block;
}

#add-restaurent-tab.step-app .step-content .step-tab-panel label sup {
    top: 0;
    color: #ff0000;
}

#add-restaurent-tab.step-app .step-content .step-tab-panel .form-group .form-group {
    display: flex;
    margin-bottom: 35px;
}

.package-box .package-caption {
    border-radius: 0 0 3px 3px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: 0;
    transition: .3s;
}

.package-box:hover .package-caption {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.package-box .package-caption .package-item-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.package-box .package-caption>ul>li:before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #268968;
    left: 0;
    top: 50%;
    margin-top: 4px;
}

.package-box .package-caption>ul>li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgb(222, 222, 222);
    position: relative;
    padding-left: 15px;
    letter-spacing: 0.4px;
}

.package-box .package-caption>ul>li:first-child {
    border: none;
}

.package-box .package-caption .package-btn {
    margin-top: 20px;
}

.general-sec iframe {
    border: none;
}

.payment-sec .card-header,
.payment-sec .card {
    border: none;
    border-radius: 0;
}

.payment-sec .card-header {
    background: #fff;
    padding-left: 0;
    padding-top: 0;
}

.payment-sec .card-header .card-link.collapsed:before {
    content: '+';
}

.payment-sec .card-header .card-link:before {
    content: '-';
    font-size: 18px;
    line-height: normal;
    position: relative;
    top: 2px;
    right: 0;
    padding-right: 10px;
    font-weight: 700;
}

.driver-tip-sec>.nav,
.payment-option-tab>.nav {
    margin-bottom: 20px;
    border-bottom: 0;
}

.payment-option-tab>.nav>.nav-item {
    flex: 0 0 20%;
    max-width: 20%;
}

.driver-tip-sec>.nav>.nav-item>.nav-link,
.payment-option-tab>.nav>.nav-item>.nav-link {
    padding: .5rem;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .1);
    border-right: 0;
    border-radius: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.driver-tip-sec>.nav>.nav-item:last-child>.nav-link,
.payment-option-tab>.nav>.nav-item:last-child>.nav-link {
    border: 1px solid rgba(0, 0, 0, .1);
}

.driver-tip-sec>.nav>.nav-item>.nav-link.active,
.payment-option-tab>.nav>.nav-item>.nav-link.active {
    color: #fff;
    background: #268968;
    border-color: #268968;
}

.payment-option-tab .credit-card:before {
    content: '';
    position: absolute;
    padding: 15px 25px;
    font-size: 18px;
    color: #6b6b83;
    pointer-events: none;
    background-image: url(../img/visa.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    top: 18px;
    left: 8px;
}

.payment-option-tab .credit-card.card-front:before {
    background-image: url(../img/card-front.jpg);
}

.payment-option-tab .credit-card.card-back:before {
    background-image: url(../img/card-back.jpg);
}

.payment-option-tab .credit-card.gift-card:before {
    background-image: url(../img/gift-box.png);
}

.payment-option-tab .credit-card.promocode:before {
    background-image: url(../img/promo.jpg);
}

.payment-option-tab .credit-card.amex-card-front:before {
    background-image: url(../img/amex-card-front.png);
}

.payment-option-tab .credit-card.amex-card-back:before {
    background-image: url(../img/amex-card-back.png);
}

.payment-option-tab .credit-card input {
    padding-left: 65px;
    width: 100%;
}

.driver-tip-sec>.nav>.nav-item {
    flex: 0 0 50%;
    max-width: 50%;
}

.driver-tip-sec .tip-percentage form {
    display: flex;
    flex-wrap: wrap;
}

.driver-tip-sec .tip-percentage input[type=radio]:checked+span {
    background: #268968;
    color: #fff;
}

.driver-tip-sec .tip-percentage label {
    position: relative;
    flex: 0 0 25%;
    max-width: 25%;
    z-index: 1;
    cursor: pointer;
}

.driver-tip-sec .tip-percentage label span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 13px .5rem;
    z-index: -1;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .1);
    border-right: 0;
    border-radius: 0;
}

.driver-tip-sec .tip-percentage label input {
    -webkit-appearance: none;
}

.driver-tip-sec .tip-percentage label:last-child span {
    border: 1px solid rgba(0, 0, 0, .1);
}

.driver-tip-sec .custom-tip .input-group .input-group-prepend {
    border: 1px solid #8f8fa1;
}

.driver-tip-sec .custom-tip .input-group .input-group-prepend span {
    border-radius: 0;
}

.thankmsg-sec .msg-wrapper h1 {
    font-size: 60px;
}

.thankmsg-sec .msg-wrapper .contact-details {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.thankmsg-sec .msg-wrapper .contact-details>li {
    font-size: 16px;
    margin: 0 25px;
}

.thankmsg-sec .msg-wrapper .contact-details>li>i {
    padding-right: 15px;
}

/*======================
7. Add Restaurent
========================*/
/*======================
3. List View
========================*/

.sort-tag-filter,
.product-list-view,
.most-popular .popular-item-slider {
    border-bottom: 1px solid rgba(67, 41, 163, .1);
}

.sort-tag-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 20px 0;
}

.restaurent-tags span.tags {
    cursor: pointer;
    margin-right: 10px;
    background: #f9f9f9;
    text-transform: uppercase;
    padding: 6px 10px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    padding-right: 35px;
    transition: .3s;
}

.restaurent-tags span.tags:hover {
    background-color: #268968;
    color: #fff;
}

.restaurent-tags span.tags span.close {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 14px;
    top: 50%;
    text-shadow: none;
    transition: .3s;
    transform: translate(0px, -50%);
}

.restaurent-tags span.tags:hover span.close {
    color: #fff;
}

.sorting {
    display: flex;
    align-items: center;
}

.sorting span {
    margin-right: 10px;
}

.sorting select {
    padding: 3px 40px 3px 8px;
    border-radius: 3px;
    text-align: left;
    float: none;
}

/*filter-sidebar*/

.filter-sidebar .title-2 small {
    margin-left: 10px;
}

.filter-sidebar .title-2 small a {
    text-decoration: underline;
}

.filter-sidebar .sidebar-tab>.nav>.nav-item {
    margin-right: 28px;
    border-radius: 0;
}

.filter-sidebar .sidebar-tab>.nav>.nav-item>.nav-link {
    position: relative;
    color: rgba(0, 0, 0, .55);
    border-radius: 0;
    border-bottom: 0;
    font-weight: 700;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
}

.filter-sidebar .sidebar-tab>.nav>.nav-item>.nav-link::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 3px;
    transition: .3s;
    background-color: #268968;
}

.filter-sidebar .sidebar-tab>.nav>.nav-item>.nav-link.active {
    background: transparent;
    color: #3c3e41;
}

.filter-sidebar .sidebar-tab>.nav>.nav-item>.nav-link.active::before {
    width: 100%;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav {
    display: flex;
    margin-bottom: 10px;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item {
    width: 50%;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item>.nav-link {
    flex: 1;
    white-space: normal;
    border: 1px solid transparent;
    transition-property: z-index, background, border-color, color;
    transition-timing-function: ease;
    transition-duration: .1s;
    border-color: rgba(0, 0, 0, .1);
    color: #268968;
    background-color: transparent;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    padding: 10px 0;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item>.nav-link.disabled {
    color: #e8e8e8;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item>.nav-link.active {
    border-color: #268968;
    color: #fff;
    background-color: #268968;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item:first-child>.nav-link {
    border-radius: 4px 0 0 4px;
}

.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item:last-child>.nav-link {
    border-radius: 0px 4px 4px 0px;
}

.filter-sidebar .sidebar-tab .tab-content p.delivery-type a:before {
    content: '';
    width: 2px;
    height: 2px;
    background: #3c3e41;
    display: inline-flex;
    margin: 0 8px;
    vertical-align: middle;
}

.filter-sidebar .sidebar-tab .filters .card-header,
.filter-sidebar .sidebar-tab .filters .card {
    border: none;
    padding: 10px 0;
    background: #fff;
}

.filter-sidebar .sidebar-tab .filters .card {
    padding: 0;
    margin-bottom: 20px;
}

.filter-sidebar .sidebar-tab .filters .card .card-body {
    padding: 0;
}

.filter-sidebar .sidebar-tab .filters .card-header a {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
}

.filter-sidebar .sidebar-tab .filters .card-header a:after {
    content: '\f077';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    transition: .3s;
}

.filter-sidebar .sidebar-tab .filters .card-header a.collapsed:after {
    transform: rotate(180deg);
}

.filters .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filters .rating button {
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 6px 10px;
    font-size: 12px;
    line-height: 19px;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    display: block;
    border-right: 0;
    font-weight: 900;
}

.filters .rating button:hover {
    background: #268968;
    color: #fff;
    border-color: #268968;
}

.filters .rating button:first-child {
    border-radius: 3px 0 0 3px;
}

.filters .rating button:last-child {
    border-radius: 0px 3px 3px 0px;
    border-right: 1px solid rgba(0, 0, 0, .1);
}

.filters .delivery-slider .irs--flat .irs-line,
.filters .delivery-slider .irs--flat .irs-bar {
    height: 2px;
    top: 30px;
    cursor: pointer;
}

.filters .delivery-slider .irs--flat .irs-bar {
    background-color: #268968;
}

.filters .delivery-slider .irs--flat .irs-grid-text {
    font-size: 16px;
    color: #3c3e41;
    padding: 0 0px 0 25px;
    top: 25px;
}

.filters .delivery-slider .irs-grid-pol.small,
.filters .delivery-slider .irs--flat .irs-grid-pol {
    display: none;
}

.filters .delivery-slider .irs--flat .irs-handle {
    width: 0;
}

.filters .delivery-slider .irs--flat .irs-handle>i:first-child {
    top: 50%;
    width: 15px;
    height: 15px;
    background-color: #268968;
    border-radius: 50%;
    transform: translate(0px, -50%);
    cursor: pointer;
}

.filters .delivery-slider .irs--flat .irs-handle.state_hover>i:first-child,
.filters .delivery-slider .irs--flat .irs-handle:hover>i:first-child {
    background-color: #268968;
}

.filters .delivery-slider .irs--flat .irs-from,
.filters .delivery-slider .irs--flat .irs-to,
.filters .delivery-slider .irs--flat .irs-single {
    background-color: #268968;
}

.filters .delivery-slider .irs--flat .irs-from:before,
.filters .delivery-slider .irs--flat .irs-to:before,
.filters .delivery-slider .irs--flat .irs-single:before {
    border-top-color: #268968;
}

.delivery-slider .irs--flat.irs-with-grid {
    width: 95%;
}

/*testimonal layout*/
.swiper-slide .testimonial-wrapper {
    margin-top: 0;
}

.video-box,
.testimonial-wrapper .testimonial-box {
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
}

.testimonial-wrapper .testimonial-box {
    background-color: #fff;
}

.testimonial-wrapper .testimonial-box .testimonial-img .brand-logo {
    position: absolute;
    top: 15px;
    left: 50%;
    right: 0;
    width: 150px;
    text-align: center;
    transform: translateX(-50%);
}

.testimonial-wrapper .testimonial-box .testimonial-img .add-fav {
    position: absolute;
    right: 0;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    background: #fff;
    width: 35px;
    height: 30px;
    transform: translate(-15px, 0%);
    bottom: -15px;
    border-radius: 5px 5px 0 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: 35px;
    height: 35px;
}

.testimonial-wrapper .testimonial-box .testimonial-img .add-fav>img {
    padding: 8px;
}

.testimonial-wrapper .testimonial-box .testimonial-user-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.testimonial-wrapper .testimonial-box .testimonial-user-box img {
    margin-right: 12px;
    margin-bottom: 8px;
}

.testimonial-wrapper .testimonial-box .testimonial-user-box .testimonial-user-name p {
    margin-bottom: 0px;
    line-height: normal;
}

.testimonial-wrapper .testimonial-box .testimonial-user-box .testimonial-user-name span {
    font-size: 12px;
    margin-left: 3px;
}

/*list view*/
.product-list-view:first-child {
    border-top: 1px solid rgba(67, 41, 163, .1)
}

.product-list-view {
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product-list-view:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.5s;
}

.product-list-view .product-list-info {
    width: 90px;
}

.product-list-view .product-right-col {
    width: 95%;
}

.product-list-view .product-list-title h6 {
    margin-bottom: 5px;
}

.product-list-view .product-list-info .product-list-img {
    flex: 0 0 90px;
    overflow: hidden;
    height: 90px;
}

.product-list-view .product-list-bottom,
.product-list-view .product-list-details {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.product-list-view .product-list-details .product-list-title {
    align-content: space-between;
    padding-left: 20px;
    max-width: 100%;
}

.product-list-view .product-list-details .product-detail-right-box {
    display: flex;
    justify-content: space-between;
    width: 60%;
}

.product-list-view .product-list-details .product-detail-right-box .product-list-tags {
    display: flex;
    flex-wrap: wrap;
}

.product-list-view .product-right-col .product-list-bottom .mob-tags-label {
    display: none;
}

.product-list-view .product-list-details .product-detail-right-box .product-list-label span {
    margin: 0;
    margin-bottom: 2px;
}

.product-list-view .product-list-details .product-detail-right-box .product-list-price .product-list-time {
    display: none;
}

.product-list-view .product-list-details .product-list-price {
    text-align: right;
}

.product-list-view .product-list-time,
.product-list-view .product-list-time ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.product-list-view .product-list-time ul li {
    padding: 0 10px;
    position: relative;
}

.product-list-view .product-list-time ul li:after {
    content: '';
    background: #6b6b83;
    width: 3px;
    height: 3px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
}

.product-list-view .product-list-time ul li:last-child {
    padding-right: 0;
}

.product-list-view .product-list-time ul li:last-child:after {
    display: none;
}

.product-list-view .product-list-bottom .product-list-type {
    padding-left: 20px;
}

.product-list-view .product-list-bottom .product-list-type span {
    display: inline-block;
}

.product-list-view .product-list-time {
    width: 27%;
    justify-content: space-between;
}

.product-list-view .product-list-time>span {
    margin-right: 20px;
}

/*=========================
8. Login & register
===========================*/
.user-page {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-page .login-sec {
    width: 420px;
    margin: 0 auto;
}

.user-page .login-sec .login-box {

    margin-top: 0px;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    background: #fff;
    padding: 30px;
}

.user-page .login-sec .login-box .checkbox-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-page .login-sec .login-box span.terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.user-page .login-sec .login-box span.terms a {
    margin: 0 3px;
}

.user-page .login-sec .login-box .form-group .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -37px;
    position: relative;
    z-index: 2;
    right: 18px;
    cursor: pointer;
}

/*=========================
9. Checkout
===========================*/

.cart-product {
    display: flex;
    align-items: center;
}

.cart-product img {
    width: 50px;
    margin-right: 10px;
}

.cart-product h6 {
    margin: 0;
}

.cart-product p {
    margin: 0;
}

.cart-product h6 a {
    color: #3c3e41;
}

.cart-product h6 a:hover {
    color: #268968;
}

.recipt-sec,
.tracking-sec {
    display: flex;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    background: #fff;
    margin-bottom: 30px;
}

.recipt-sec {
    display: block;
}

.tracking-sec .tracking-map,
.tracking-sec .tracking-details {
    flex: 0 0 50%;
    max-width: 50%;
}

#add-listing-tab1 .step-steps,
#add-listing-tab .step-steps {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
}

.step-app>.step-steps {
    overflow: hidden;
    margin: 0 auto;
}

#add-listing-tab1 .step-steps>li,
#add-listing-tab .step-steps>li {
    flex: 0 0 25%;
    max-width: 25%;
}

.step-app>.step-steps>li {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
}

#add-listing-tab1 .step-steps>li:before,
#add-listing-tab .step-steps>li:before {
    background-color: #e3e3e3;
    height: 12px;
    left: 45%;
    top: 19px;
    width: 100%;
    transition: all 0.3s;
    margin-left: 4px;
}

#add-listing-tab1 .step-steps>li:first-child:before,
#add-listing-tab .step-steps>li:first-child:before {
    border-radius: 10px 0 0 10px;
}

.step-app>.step-steps>li:before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 2px;
    background-color: #C4CAD2;
    top: 35px;
    left: 85px;
}

.step-app>.step-steps>li>a,
.step-app>.step-steps>li>a>span {
    display: block;
}

#add-listing-tab1 .step-steps>li>a>.number,
#add-listing-tab .step-steps>li>a>.number {
    width: 12px;
    height: 12px;
    background-color: #b6b6b6;
    border: 0px solid;
    transition: all 0.3s;
}

.step-app>.step-steps>li>a>.number {
    border-radius: 50%;
    color: #b6b6b6;
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
    margin: 10px auto;
    position: relative;
    z-index: 0;
    top: 9px;
}

#add-listing-tab1 .step-steps>li.go2998626915>a>.number,
#add-listing-tab1 .step-steps>li.go2998626915>a>.number,
#add-listing-tab .step-steps>li.go2998626915>a>.number,
#add-listing-tab .step-steps>li.go2998626915>a>.number {
    background-color: #7abd7a;
}

#add-listing-tab1 .step-steps>li>a>.step-name,
#add-listing-tab .step-steps>li>a>.step-name {
    font-weight: 600;
    color: #268968;
    font-size: 12px;
    margin-top: 20px;
    transition: all 0.3s;
    white-space: normal;
}

#add-listing-tab1 .step-steps>li:last-child:before,
#add-listing-tab .step-steps>li:last-child:before {
    width: 0;
}

#add-listing-tab1 .step-steps>li.go2998626915:before,
#add-listing-tab .step-steps>li.go2998626915:before {
    background-color: #8fde8f;
    ;
}

.tracking-sec .tracking-map #pickupmap {
    height: 100%;
    width: 100%;
    border: none;
    min-height: 300px;
}

.tracking-sec .tracking-details .fullpageview {
    position: absolute;
    bottom: 20px;
}

.md-modal {
    margin: auto;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 320px;
    height: 100%;
    z-index: 2000;
    visibility: hidden;
    backface-visibility: hidden;
}

.md-show {
    visibility: visible;
}

.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: #fff;
    transition: all 0.3s;
}

.md-show~.md-overlay {
    opacity: 1;
    visibility: visible;
}

.md-effect-12 .md-content {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
    height: 100%;
}

.md-show.md-effect-12~.md-overlay {
    background-color: #fff;
}

.md-show.md-effect-12 .md-content {
    transform: scale(1);
    opacity: 1;
}

.md-modal .md-content #pickupmap2 {
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps>li:after {
    content: "";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #00a000;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    top: 0;
}

.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps>li.order-note:after {
    content: "\f328";
}

.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps>li.make-order:after {
    content: "\f447";
}

.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps>li.bike:after {
    content: "\f21c";
}

.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps>li.home-delivery:after {
    content: "\f015";
}

.md-modal .md-content .modal-sidebar .arrow {
    position: absolute;
    right: 50px;
}

.md-modal .md-content .modal-sidebar .rating-box .ratings span {
    cursor: pointer;
}

.md-modal .md-content .modal-sidebar .rating-box .ratings span:hover i {
    color: #268968;
}

.modal-header,
.modal-body {
    padding: 20px;
}

.recipt-sec .recipt-name.title {
    align-items: center;
    padding-bottom: 20px;
}

.countdown-box {
    display: flex;
}

.countdown-box .time-box {
    text-align: center;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: #268968;
    width: 80px;
    height: 80px;
    border-radius: 3px;
    font-size: 22px;
}

.countdown-box .time-box span {
    line-height: 1;
}

.countdown-box .time-box span h6 {
    margin-top: 5px;
    color: #fff;
}

.recipt-sec .recipt-name span {
    display: block;
}

.checkout-product {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.checkout-product .img-name-value {
    display: flex;
    align-items: flex-start;
}

.checkout-product .img-name-value .product-value {
    margin: 0 30px;
}

.payment-method .method-type {
    display: flex;
    align-items: center;
}

.payment-method .method-type i {
    font-size: 40px;
    margin-right: 15px;
}

.price-table .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout-page .help-btn {
    margin: 0px 0;
}

.checkout-page .d-flex {
    align-items: center;
    justify-content: center;
}

/*=========================
10. Final Order
===========================*/
.final-order .title2 span {
    margin-left: 140px;
}

.final-order .advertisement-img,
.final-order .advertisement-img img {
    height: 100%;
}

/*=========================
11. Geo Locator
===========================*/

.inner-wrapper {
    height: inherit;
    position: relative;
}

.main-padding {
    padding: 64px 100px;
}

.section-2,
.main-banner {
    height: 100%;
}

.main-banner .main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.main-banner .footer-img {
    position: absolute;
    bottom: 0;
    max-width: 340px;
    right: 0;
}


.inner-wrapper .main-page .login-box {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.inner-wrapper .main-page .login-box .input-group-prepend {
    height: 47px;
    min-width: 44px;
    flex-basis: 44px;
    border: 1px solid #8f8fa1;
    border-left: 0;
    border-radius: 0px 4px 4px 0;
    display: none;
}

.inner-wrapper .main-page .login-box .input-group-prepend .input-group-text {
    line-height: 1.5;
    font-size: 20px;
    background: transparent;
    border: none;
}

.inner-wrapper .main-page .login-box .input-group .input-group2 {
    display: flex;
}

.inner-wrapper .main-page .login-box .input-group-append button {
    white-space: normal;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.inner-wrapper .main-page .login-box h1 {
    margin-bottom: 30px;
    max-width: 700px;
}

.inner-wrapper .main-page .login-box p {
    max-width: 600px;
    margin: 0 0 30px;
    font-size: 16px;
}

/*=========================
12. About Us
===========================*/
/*slider*/
.about-us-slider .slide-item {
    height: 700px;
}

.about-us-slider .slide-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.about-us-slider .slide-item .right-side-content h1 {
    font-size: 60px;
    line-height: 1.2;
}

.about-us-slider .slide-item .right-side-content {
    transition: all 1s linear;
    transition-delay: 1s;
}

.about-us-slider .slide-item .right-side-content h3,
.about-us-slider .slide-item .right-side-content h4,
.about-us-slider .slide-item .right-side-content a,
.about-us-slider .slide-item .right-side-content h1 {
    transition: all .8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
}

.about-us-slider .slide-item.swiper-slide-active .right-side-content a,
.about-us-slider .slide-item.swiper-slide-active .right-side-content h3,
.about-us-slider .slide-item.swiper-slide-active .right-side-content h4,
.about-us-slider .slide-item.swiper-slide-active .right-side-content h1 {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

/*Slider*/
.slider-area {
    height: 700px;
    width: 100%;
    position: relative;
    font-weight: 900;
    color: #fff
}

.slider-area h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
}

.slider-area h1 span {
    font-weight: 700
}

.slider-area h2 {
    font-size: 60px;
    text-transform: capitalize;
    color: #fff;
}

.slider-area h3 {
    font-weight: 700;
    text-transform: uppercase;
}

.pogoSlider {
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    padding-bottom: 0
}

.pogoSlider-slide .row {
    z-index: 1;
}

.pogoSlider-slide {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-size: cover;
    display: flex;
    height: 100%;
    overflow: hidden;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    width: 100%;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 700px;
}

.pogoSlider-dir-btn .fa-angle-left:before {
    transform: rotate(45deg);
    margin-top: 17px;
}

.pogoSlider-dir-btn .fa-angle-left:after {
    transform: rotate(-45deg);
}

.pogoSlider-dir-btn .fa-angle-right:before {
    transform: rotate(45deg);
}

.pogoSlider-dir-btn .fa-angle-right:after {
    transform: rotate(-45deg);
    margin-top: 17px;
}

.pogoSlider-dir-btn .fa-angle-left:before,
.pogoSlider-dir-btn .fa-angle-left:after,
.pogoSlider-dir-btn .fa-angle-right:before,
.pogoSlider-dir-btn .fa-angle-right:after {
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    position: absolute;
    content: '';
    height: 2px;
    width: 25px;
    top: 25px;
}

.pogoSlider-dir-btn {
    opacity: 1;
}

/*aboutus*/
.aboutus .history-title {
    margin-top: -7px;
}

.aboutus .history-title>a {
    margin-top: 20px;
}

.aboutus .history-title h2 {
    font-size: 40px;
}

.hr-about-contact-info .info-column {
    position: relative;
    margin-top: 30px;
}

.hr-about-contact-info .info-column:first-child:before {
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    height: 110px;
    width: 2px;
    background: #268968;
    transform: translateY(-50%);
}

.hr-about-contact-info .info-column:first-child:after {
    position: absolute;
    content: "or";
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    top: 50%;
    right: -14px;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #fff;
    transform: translateY(-50%);
    box-shadow: 0 8px 16px 0 rgba(67, 151, 188, .3);
}

.hr-about-contact-info .info-column:last-child {
    padding-left: 40px;
}

.hr-about-contact-info .info-column .icon-box {
    position: relative;
}

.hr-about-contact-info .info-column .icon-box .icon {
    width: 70px;
}

.hr-about-contact-info .info-column .icon-box h5 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
}

.hr-about-contact-info .info-column .icon-box h2 {
    font-size: 22px;
    font-weight: 700;
}

/*how it works*/
.how-it-works .how-it-works-box {
    text-align: center;
    padding: 20px 0px;
    cursor: pointer;
}

.how-it-works .how-it-works-box .how-it-works-box-inner {
    max-width: 250px;
    margin: 0 auto;
}

.how-it-works .how-it-works-box .how-it-works-box-inner h6,
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box {
    margin-bottom: 15px;
}

.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box:after {
    content: '';
    width: calc(100% + 45px);
    border: 2px dashed #ffcd34;
    position: absolute;
    left: 100%;
    right: 100%;
}

.how-it-works .row>div:last-child .how-it-works-box .how-it-works-box-inner .icon-box:after {
    display: none;
}

.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #268968;
    margin: 0 auto 15px;
    position: relative;
}

.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box img {
    padding: 10px;
}

/*feedback-area-two*/
.feedback-area-two {
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: #3c3e41;
    opacity: 0.9;
}

.feedback-area-two .feedback-item-two {
    max-width: 800px;
    margin: 15px auto 0;
}

.feedback-area-two .feedback-item-two img {
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    border: 4px solid #ffffff;
    margin-bottom: 17px;
}

.feedback-area-two .feedback-item-two p {
    font-style: italic;
    margin-bottom: 15px;
}

.feedback-area-two .feedback-item-two span {
    line-height: 1;
    display: block;
    margin-top: 9px;
}

/* Promo video bg */
.promo-video-outer {
    position: relative;
    width: 170px;
    height: 170px;
    margin: 0 auto;
}

.promo-video-outer .promto-play-inner {
    display: block;
    width: 100%;
    height: 100%;
}

.promo-video-outer svg {
    width: 100%;
    height: 100%;
}

.promo-video-outer .video-button-stroke {
    fill: none;
    stroke: currentColor;
    stroke-width: 1;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    position: relative;
    z-index: -1;
    opacity: .5;
    color: #fff;
}

.v-icon {
    color: #fff;
}

.promo-video-outer .video-button-circle {
    fill: none;
    stroke: currentColor;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    stroke-dashoffset: 700;
    stroke-dasharray: 700;
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-linecap: butt;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    color: #fff;
}

.promo-video-outer:hover svg .video-button-circle {
    stroke-dashoffset: 0;
    stroke-dasharray: 700;
}

.promo-video-bg {
    background-image: url('../img/promo-video-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

}

.bar {
    display: inline-flex;
    margin-right: 6px;
}

.bar svg {
    width: 35px;
    height: auto;
}

.light svg {
    fill: #ffffff;
}

.dark svg {
    fill: #268968;
}

.foot-style-2 a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.foot-style-2 .ft-social-media ul li {
    border-color: #ffffff;
}

.foot-style-2 .ft-social-media ul li a {
    color: #ffffff;
}

.foot-style-2 .ft-social-media ul li:hover,
.foot-style-2 .ft-social-media ul li:hover a {
    color: #3c3e41;
    border-color: #3c3e41;
    text-decoration: none;
}

.foot-style-2 .btn-submit {
    color: #fff;
    background: #e4821b;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-color: #e4821b;
}

.sale-products .product-item {
    border: 1px solid transparent;
    transition: .3s;
}

.sale-products .product-item:hover .product-info a {
    color: #268968;
}

.sale-products .product-item:hover {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
}

/* Shop-details */
.detail-slider .gallery-top .swiper-slide .shop-type-tag {
    position: absolute;
    top: 20px;
    right: 20px;

}

.detail-slider .gallery-top .swiper-slide .shop-type-tag a {
    font-weight: 600;
    display: inline-block;
    padding: 6px 14px;
    height: auto;
    border-radius: 3px;
    font-size: 12px;
    letter-spacing: 0;
    color: #ec59ad;
    cursor: default;
    background-color: #ffeff8;
}

.pro-review {
    padding-left: 10px;
}

.pro-review span {
    font-size: 14px;
    line-height: 1;
    color: #555555;
}

.shop-detail-contents .price .price-tag {
    font-size: 25px;
    font-weight: 500;
    color: #e05d41;
}

.shop-detail-contents .price h6 span {
    margin-left: 5px;
    text-decoration: line-through;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 500;
}

.detail-slider .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 15px 0;
}

.type-tag {
    color: #fff;
    display: inline-block;
    padding: 3px 14px;
    font-size: 14px;
}

.type-tag:hover {
    color: #fff;
}

.shop-detail-contents .title-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.shop-detail-contents .title-box .strain-box {
    border-radius: .13333rem;
    border: 1.5px solid #268968;
    font-size: .8rem;
    color: #268968;
    font-weight: 700;
    line-height: .8rem;
    padding: 4px 8px 5px;
}

.shop-detail-contents .product-quality {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}


.shop-detail-contents .product-quality .label-wrpr+.label-wrpr {
    margin-left: 20px;
}

.shop-detail-contents .title-box .strain-box.border-purple {
    color: #e75d41;
    border-color: #e75d41;
    margin-left: 8px;
}

.shop-detail-contents .product-quality .thc {
    display: inline-block;
    font-size: 15px;
}

.shop-detail-contents .product-quality .cbd {
    margin-left: 12px;
}

.shop-detail-content-wrapper {
    max-width: 435px;
}

.shop-detail-contents .quantity {
    width: 380px;
    display: flex;
    align-items: center;
}


.shop-detail-contents .quantity input {
    border-right: 0;
    border-left: 0;
}

.shop-detail-contents .price {
    font-size: 24px;
    font-weight: 600;
    color: #268968;
    display: block;
    margin-bottom: 20px;
}
.shop-detail-contents .price span+span{
    text-decoration: line-through;
    color: #c1c1c1;
    font-size: 12px;
    display: inline-block;
    margin-left: 10px;
}
.product-qty-input {
    width: 40%;
    display: flex;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 25px;
}

.form-control-custom {
    height: 40px;
    padding: 0.375rem 1.2rem;
    border: 0px;
    width: 100%;
    background: none;
}

.shop-detail-contents .quantity button {
    height: 40px;
    border: 0px;
    padding: 0 20px;
    color: rgba(0, 0, 0, .1);
    transition: .3s;
    border-radius: 25px 0 0 25px;
}

.shop-detail-contents .quantity button.plus-btn {
    border-radius: 0 25px 25px 0;
}

.shop-detail-contents .quantity button:hover {
    background-color: #f9f9f9;
    color: #3c3e41;
}

.shop-detail-contents .form-control-qty {
    height: 40px;
    padding: 0.9rem 0.5rem;
    border-radius: 0;
    border: 0px;
    width: 50%;
    background: none;
}

.shop-detail-contents .shop-bottom .shop-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.shop-detail-contents .shop-bottom .shop-meta a {
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: 12px;
    color: #6b6b83;
    padding: 3px 6px;
    display: block;
}

.shop-detail-contents .shop-bottom .shop-meta a:hover {
    background-color: #268968;
    color: #fff;
}

.shop-detail-description-sec {
    padding-top: 80px;
}

.shop-detail-description-sec .nav-tabs {
    border: 0;
}

.shop-detail-description-sec .nav-tabs .nav-item {
    margin-right: 5px;
}

.shop-detail-description-sec .nav-tabs .nav-item:last-child {
    margin-right: 0px;
}

.shop-detail-description-sec .nav-tabs .nav-item .nav-link {
    color: #fff;
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-bottom: none;
    background-color: #fff;
    color: #3c3e41;
}

.shop-detail-description-sec .nav-tabs .nav-item .nav-link.active {
    background-color: #268968;
    color: #fff;
}

.shop-detail-description-sec .nav-tabs .nav-item .nav-link:not(.active):hover {
    color: #268968;
    background-color: #f9f9f9;
}

.shop-detail-description-sec .tabs .tab-content {
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 40px 40px 20px;
    background-color: #ffffff;
}

.shop-detail-description-sec .tabs .tab-content h3 {
    margin-bottom: 40px;
}

.shop-detail-description-sec .tabs .tab-content .comment-box {
    margin-bottom: -20px;
}

.shop-detail-description-sec .comment-box .children,
.shop-detail-description-sec .comment-box .comments {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 35px;
    list-style: inherit;
}

.shop-detail-description-sec .comment-box .comments li.comment {
    list-style: outside none none;
    padding-left: 0;
    margin-bottom: 20px;
}

.shop-detail-description-sec .comment-box .comments li article .comment-avatar {
    margin-right: 20px;
    overflow: hidden;
    border-radius: 50px;
}

.shop-detail-description-sec .comment-box .comments li article .comment-content {
    flex: 1;
}

.shop-detail-description-sec .comment-box .comments li article .comment-content .comment-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.shop-detail-description-sec .comment-box .comments li article .comment-content .ratings {
    margin-bottom: 10px;
}

.shop-detail-description-sec .comment-box .comments li article .comment-content .comment-meta-reply {
    margin-top: 15px;
}

.shop-detail-description-sec .comment-box .comments li article .comment-content .comment-meta-header {
    display: flex;
    align-items: center;
}

.shop-detail-description-sec .comment-box .comments li article .comment-content .comment-meta-header h5 {
    margin: 0;
    font-weight: 500;
}

.shop-detail-description-sec .btn-first {
    border: 1px solid rgba(0, 0, 0, .1);
}

.shop-detail-description-sec .btn-first:hover {
    border: 1px solid #268968;
}

.shop-detail-description-sec .post-date {
    color: #6b6b83;
    margin-left: 10px;
}

.shop-detail-description-sec .comment-box .children {
    padding-left: 20px;
}

.shop-detail-description-sec .comment-box .comments li article {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}

/* Shop-Left */


.Weedo-shop-left .sidebar-widget {
    margin-bottom: 28px;
}
.Weedo-shop-left .sidebar-widget .nice-select.custom-select-2{
    float: none;
    margin-bottom: 0;
}
.Weedo-shop-left .sidebar-widget .blog-tags {
    padding-bottom: 10px;
}

.Weedo-shop-left .sidebar-widget .custom-select-trigger {
    line-height: 54px;
}

.Weedo-shop-left .sidebar-widget .custom-select-trigger:before {
    height: 55px;
}

.Weedo-shop-left .sidebar-widget h4 {
    font-size: 22px;
    margin-bottom: 15px;
}

.Weedo-shop-left .sidebar-widget .Weedo-links {
    border-radius: 3px;
    border: 1px solid #e3e8f0;
    color: #3c3e41;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
    padding: 16px 20px;
}

.Weedo-shop-left .sidebar-widget .Weedo-links .number-box {
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 3px;
    background-color: #e3e8f0;
    color: #3c3e41;
    font-weight: 500;
}

.Weedo-shop-left .sidebar-widget .Weedo-links:hover {
    border-color: #268968;
}

.center {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

select {
    z-index: 3;
}

.custom-select-wrapper {
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    width: 100%;
}

.custom-select-wrapper select {
    display: none;
}

.custom-select-2 {
    position: relative;
    font-size: 14px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, .1);
    width: 100%;
}

.custom-select-2>span {
    line-height: 48px;
}
.nice-select.custom-select-2{
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.5;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
}
.nice-select.custom-select-2>span{
    line-height: normal;
}
.custom-select-trigger {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 35px;
    padding: 0 104px 0 25px;
    font-weight: 500;
    color: #3c3e41;
    background-color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.custom-select-trigger:before {
    position: absolute;
    display: block;
    content: "";
    width: 1px;
    height: 36px;
    top: 3px;
    right: 50px;
    margin-top: -3px;
    border-right: 1px solid #c7d1d6;
    transition: all 0.35s ease-out;
    transform-origin: 50% 0;
}

.custom-select-trigger:after {
    position: absolute;
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    right: 20px;
    margin-top: -3px;
    border-bottom: 1px solid #c7d1d6;
    border-right: 1px solid #c7d1d6;
    transform: rotate(45deg) translateY(-50%);
    transition: all 0.35s ease-out;
    transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
    margin-top: 3px;
    transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0%;
    right: 0;
    width: 100%;
    margin: 10px 0;
    border-radius: 3px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px);
    border: 1px solid rgba(0, 0, 0, .1);
}

.custom-select.opened .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(-8px);
}

.option-hover:before {
    background: #268968;
}

.custom-option {
    position: relative;
    display: block;
    padding: 0 22px;
    font-weight: 500;
    color: #7e7c7c;
    background-color: #fafafa;
    line-height: 47px;
    cursor: pointer;
    transition: all 0.05s ease-in-out;
}

.custom-option:hover,
.custom-option.selection {
    color: #fff;
    background-color: #268968;
}

/* Product-Reviews */

.Weedo-reviews .main-review-wrapper {
    border-bottom: .06667rem solid #e3e8f0;
    margin-bottom: 50px;
}

.Weedo-reviews .main-review-wrapper:nth-child(3) {
    border: 0;
    margin-bottom: 0;
}

.Weedo-reviews .sidebar-widget {
    margin-bottom: 50px;
}

.Weedo-reviews .sidebar-widget .custom-select-wrapper {
    width: auto;
}

.Weedo-reviews .sidebar-widget .custom-options {
    z-index: 1;
}

.Weedo-reviews .product-review-wrapper .review-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.Weedo-reviews .product-review-wrapper .about-product {
    max-width: 550px;
}

.Weedo-reviews .product-review-wrapper .about-product p {
    margin: 0;
}

.product-review-wrapper .review-header .reviewer-image {
    border-radius: 50%;
    margin-right: 26px;
}

.product-review-wrapper .review-header .reviewer-image img {
    border-radius: 50%;
}

.product-review-wrapper .review-header .reviewer-say {
    display: flex;
    align-items: center;
    flex: auto;
    flex-wrap: wrap;
    justify-content: space-between;
}

.review-header .reviewer-say .about-reviewer a {
    display: block;
    color: #3c3e41;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 8px;
}

.review-header .reviewer-say .about-reviewer span {
    border-radius: 3px;
    border: 1.5px solid #e75d41;
    font-size: 13px;
    display: block;
    text-align: center;
    color: #e75d41;
    font-weight: 700;
    line-height: 1;
    padding: 5px 8px 4px;
}

.review-header .reviewer-say .about-reviewer .border-blue {
    border: 1.5px solid #268968;
    color: #268968;
}

.review-header .reviewer-say .about-reviewer .border-green {
    border: 1.5px solid #13aa37;
    color: #13aa37;
}

.product-review-wrapper .total-reviews,
.product-review-wrapper h4 {
    margin-bottom: 10px;
}

.product-review-wrapper h4 {
    font-size: 20px;
}

.product-review-wrapper .total-reviews span {
    font-size: 14px;
    font-weight: 700;
}

.product-review-wrapper .review-rating {
    margin-top: 10px;
}

.product-review-wrapper h4 a {
    color: #3c3e41;
}

.product-review-wrapper h4 a:hover {
    color: #268968;
}

.product-review-wrapper .review-rating span {
    display: block;
    line-height: normal;
    font-size: 18px;
}

.product-description-left .buy-again p {
    display: inline-block;
    color: #3c3e41;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
}

.product-description-left .buy-again span {
    font-size: 18px;
    color: #268968;
    font-weight: 500;
}

.product-description-left .extra-information h5 {
    margin-bottom: 4px;
    font-weight: 600;
}


/* Vendor Map */
.Weedo-vendors-map .map-product-wrap {
    padding: 45px 30px;
}

.Weedo-vendors-map .Weedo-map-heading {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 0 0 20px;
}

.Weedo-vendors-map .Weedo-map-heading .section-header-left {
    margin: 0 auto;
    text-align: left;
    padding-bottom: 0;
}

.Weedo-vendors-map .sidebar-widget.range-widget {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 0 0 30px;
    margin: 0 0 30px;
}


.Weedo-vendors-map .sidebar-widget.range-widget .custom-options {
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, .1);
}


option[value=""][disabled] {
    display: none;
}

.Weedo-vendors-map .sidebar-widget.product-widget {
    margin-bottom: 0;
}

.Weedo-vendors-map .sidebar-widget h4 {
    font-size: 18px;
}

.Weedo-vendors-map .postal-code-bar .input-group input:focus {
    border: 1px solid #ced4da;
}

.Weedo-vendors-map .sidebar-widget .product-footer .map-btn {
    background-color: transparent;
    color: #268968;
    border: none;
    font-weight: 700;
}

.Weedo-vendors-map .vendors-map {
    height: 100%;
}

.Weedo-vendors-map .sidebar-widget .delievery-box {
    display: flex;
    align-items: center;
}

.Weedo-vendors-map .sidebar-widget .delievery-box p {
    display: inline-block;
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 0;
}

.Weedo-vendors-map .product-box .product-caption .product-title h6 a {
    margin: 10px 0;
}

.Weedo-vendors-map .product-box .product-caption .product-title h6 a:hover {
    color: #268968;
}

.Weedo-vendors-map .sidebar-widget .delievery-box span {
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: #268968;
    font-size: 15px;
}

.Weedo-vendors-map .sidebar-widget .delievery-box span i {
    color: #268968;
    font-size: 24px;
    font-weight: 600;
}

.Weedo-vendors-map .custom-pagination {
    margin: 0 auto;
}

.vendors-map .mapboxgl-map {
    width: 100%;
    height: 100%;
}

.vendors-map .mapboxgl-map .mapboxgl-popup-content a {
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
}

/* listing-archive */

.Weedo-listing-archive .herrer-alphabets ul {
    margin-top: 20px;
}

.herrer-alphabets ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.herrer-alphabets ul li a {
    display: block;
    color: #268968;
    background: #f7f8f9;
    font-size: 16px;
    text-align: center;
    width: 40px;
    height: 40px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
}

.herrer-alphabets ul li a:hover {
    background-color: #268968;
    color: #fff;
}

.shop-container .item-btm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shop-container .item {
    display: block;
    transition: 0.3s all;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.shop-container .item:hover {
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.shop-container .item h4 {
    margin-bottom: 15px;
    font-size: 20px;
}

.shop-container .item h4 a {
    color: #3c3e41;
}

.shop-container .item h4 a:hover {
    color: #268968;
}

.shop-container .item h4 p {
    margin-bottom: 15px;
}

.shop-container .item-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
}

.shop-container .item-img {
    width: 100%;
    margin: 20px 0;
    position: relative;
}

.shop-container .item-img img {
    transition: 0.5s all;
}

.shop-container .type-right a:hover {
    color: #ffcd34;
    transform: translateY(-5px);
}

.shop-container.shop-container-2 .item-img {
    margin: 0 0 20px;
    text-align: center;
}

.shop-container .type-right a+a {
    margin-left: 10px;
}

.shop-container .item-title {
    font-size: 20px;
    margin: 0 0 10px;
    font-weight: 700;
}

.shop-container .item-cat {
    display: block;
    font-size: 12px;
}

.shop-container .item-price-wrpr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;
    font-size: 12px;
}

.shop-container .item-price-wrpr .listing-cart {
    width: 40px;
    padding: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.shop-container .price {
    font-size: 16px;
    font-weight: 600;
}

.shop-container .price span+span {
    text-decoration: line-through;
    color: #c1c1c1;
    margin-left: 5px;
    font-size: 12px;
    display: inline-block;
}

.shop-container .add2cart:before {
    margin-left: 12px;
}

.shop-container .info {
    text-decoration: underline;
}

.shop-container .add2cart:hover,
.shop-container .add2cart:focus,
.shop-container .type-right>a:hover,
.shop-container .type-right>a:focus {
    text-decoration: none;
}

.label-wrpr {
    font-size: 13px;
}

.thc-label-wrpr span {
    display: block;
}

.thc-dots {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.thc-dots span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 3px;
    background-color: #c1c1c1;
}

.thc-dots span.active {
    background-color: #268968;
}

.thc-wrpr {
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: auto;
    margin: 0;
    line-height: 1;
}

.progress-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    background: #fff;
    border-radius: 0;
}

.thc-wrpr img {
    position: relative;
    z-index: 2;
}

.strike {
    text-decoration: line-through;
    color: #c1c1c1;
    margin-left: 5px;
    font-size: 12px;
    display: inline-block;
}

/* listing-detail */

.product-listing-detail .product-detail-description .listing-info-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.listing-info-1 .listing-info-1-right a,
.product-detail-description .listing-info-5 a,
.listing-info-6 a {
    text-decoration: underline;
    display: inline-block;
    margin-left: 8px;
}

.product-listing-detail .product-detail-description .listing-info-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.product-listing-detail .product-detail-description .listing-info-4,
.product-listing-detail .product-detail-description .listing-info-3 {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.product-listing-detail .product-detail-description .listing-info-3 h5,
.product-listing-detail .product-detail-description .listing-info-4 h5 {
    margin-bottom: 15px;
}

.listing-info-3 .product-badging {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.listing-info-3 .product-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 120px;
}

.listing-info-3 .product-icons .icon {
    color: #fff;
    margin-right: 8px;
    transition: .3s;
    line-height: 1;
}

.listing-info-3 .product-icons .icon svg {
    width: 20px;
    height: 20px;
    transition: .3s;
}

.listing-info-3 .product-icons .icon-wrapper {
    transition: .3s;
}

.listing-info-3 .product-icons .icon-wrapper.icon-pepery {
    color: #ec59ad;
    background-color: #ffeff8;
}

.listing-info-3 .product-icons .icon-wrapper.icon-pepery svg {
    fill: #ec59ad;
}

.listing-info-3 .product-icons .icon-wrapper.icon-pepery:hover {
    color: #fff;
    background-color: #ec59ad;
}

.listing-info-3 .product-icons .icon-wrapper.icon-citrus {
    color: #ffb354;
    background-color: #fff3e4;
}

.listing-info-3 .product-icons .icon-wrapper.icon-citrus svg {
    fill: #ffb354;
}

.listing-info-3 .product-icons .icon-wrapper.icon-citrus:hover {
    color: #fff;
    background-color: #ffb354;
}

.listing-info-3 .product-icons .icon-wrapper.icon-hoppy {
    background-color: #f5ffdb;
    color: #97d700;
}

.listing-info-3 .product-icons .icon-wrapper.icon-hoppy svg {
    fill: #97d700;
}

.listing-info-3 .product-icons .icon-wrapper.icon-hoppy:hover {
    color: #fff;
    background-color: #97d700;
}

.listing-info-3 .product-icons .icon-wrapper.icon-pepery:hover svg,
.listing-info-3 .product-icons .icon-wrapper.icon-citrus:hover svg,
.listing-info-3 .product-icons .icon-wrapper.icon-hoppy:hover svg {
    fill: #fff;
}


.listing-info-3 .product-icons .icon-wrapper {
    margin-right: 15px;
    display: flex;
    color: #fff;
    font-weight: 600;
    align-items: center;
    padding: 6px 10px;
}

.listing-info-4 .product-bars {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.listing-info-4 .product-bars .bar-parent {
    width: 50%;
}

.listing-info-4 .product-bars .bar-parent1 {
    width: 30%;
}

.listing-info-4 .product-bars .bar-parent2 {
    width: 20%;
}

.listing-info-4 .product-bars>div span {
    margin-top: 5px;
    display: block;
    font-size: 13px;
}

.listing-info-4 .product-bars .bar-1 {
    background-color: #ec59ad;
    height: 8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.listing-info-4 .product-bars .bar-2 {
    background-color: #ffb354;
    height: 8px;
    border-radius: 0;
}

.listing-info-4 .product-bars .bar-3 {
    background-color: #97d700;
    height: 8px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.product-listing-detail .product-detail-description .listing-info-5 {
    padding: 20px 0;
}

.product-listing-detail .product-detail-description .listing-info-6 {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 3px;
}

.product-listing-detail .product-detail-description .listing-info-6 p {
    padding-top: 10px;
}

.listing-info-6 .listing-info-6-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 5px;
}

.listing-info-6 .listing-info-6-top span {
    display: block;
    font-size: 13px;
}

.listing-info-6-bar {
    width: 100%;
    display: flex;
    height: 10px;
}

.listing-info-6-bar .bar-1 {
    width: 30%;
    background-color: #e38619;
}

.listing-info-6-bar .bar-2 {
    width: 10%;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .1);
}

.listing-info-6-bar .bar-3 {
    width: 60%;
    background: transparent linear-gradient(90deg, #e28718 0%, #F64B3C 100%) 0% 0% no-repeat padding-box;
}

.listing-reviews .product-review-wrapper .about-product {
    max-width: none;
}

.effect-strain-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.bg-light-green .effect-strain-header {
    border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.Weedo-effects .effect-additional-info {
    padding: 0 0 20px;
}

.effect-bar-wrapper .effect-bars .transparent-bar {
    background-color: rgba(67, 151, 188, 0.26);
    width: 100%;
    height: 12px;
    position: relative;
    overflow: hidden;
}

.effect-bars .transparent-bar:after {
    background: repeating-linear-gradient(90deg, #3c3e41, #fff 1px, transparent 1px, transparent 20%);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.effect-bars .transparent-bar .overlapped {
    position: absolute;
    left: 0;
    top: 0;
    height: 12px;
    background: transparent linear-gradient(90deg, #e28718 0%, #F64B3C 100%) 0% 0% no-repeat padding-box;
}

.effect-bar-wrapper .effect-bar-title h4 {
    font-size: 22px;
}

.wd-56 {

    width: 56%;
}

.wd-52 {

    width: 52%;
}

.wd-49 {

    width: 49%;
}

.wd-39 {

    width: 39%;
}

.wd-28 {

    width: 28%;
}

.wd-28 {

    width: 28%;
}

.wd-23 {

    width: 23%;
}

.wd-23 {

    width: 23%;
}

.wd-20 {

    width: 20%;
}

.wd-12 {

    width: 12%;
}

.wd-24 {

    width: 24%;
}

.wd-11 {

    width: 11%;
}

.wd-4 {

    width: 4%;
}

.wd-4 {

    width: 4%;
}

.wd-5 {

    width: 5%;
}

.Weedo-product-gallery .row {
    margin: 0 2.5px;
}

.Weedo-product-gallery .row>div {
    padding: 0 2.5px;
}

.Weedo-product-gallery .gallery-img img {
    width: 100%;
    object-fit: cover;
    border-radius: 0;
    height: 100%;
    object-position: center;
}

.Weedo-product-gallery .gallery-img {
    width: 100%;
    height: 295px;
    margin-bottom: 4px;
    transition: 0.4s ease;
    position: relative;
}

.Weedo-product-gallery .gallery-img a:before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.4s ease;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
}

.Weedo-product-gallery .gallery-img a:after {
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    color: #fff;
    font-size: 26px;
    content: "\f002";
    transition: 0.4s ease;
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
}

.Weedo-product-gallery .gallery-img:hover a:before,
.Weedo-product-gallery .gallery-img:hover a:after {
    opacity: 1;
    visibility: visible;
}

.Weedo-product-gallery .gallery-img:hover a:after {
    transform: translate(-50%, -50%) scale(1);
}

.strain-video .video-header {
    padding-bottom: 8px;
    border-bottom: 2px solid #3c3e41;
}

.strain-video .video-box {
    margin-top: 15px;
    margin-bottom: 0;
}


/* Inner-intro */

#inner_header_about {
    background: url('../img/checkoutCustom.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.herrer-breadcrumbs {
    z-index: 1;
}

.herrer-breadcrumbs .overlay {
    z-index: -1;
    background: #c1c1c1;
    opacity: 0;
}

.herrer-breadcrumbs .breadcrumb {
    background: none;
    padding: 0;
    margin: 0 auto 15px;
    display: block;
    border: none;
}

.herrer-breadcrumbs .breadcrumb ul {
    padding: 0;
    margin: 0 auto;
    list-style: none;
}

.herrer-breadcrumbs .breadcrumb ul li.breadcrumb-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    color: #268968;
    font-weight: 500;
    padding-left: 0;
    font-size: 16px;
}

.herrer-breadcrumbs .breadcrumb ul li a {
    color: #fff;
    font-weight: 500;
}

.herrer-breadcrumbs .breadcrumb ul li.go2998626915 {
    color: #ffcd34;
    text-transform: capitalize;
}

.herrer-breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding: 0 8px;
    color: #fff;
    content: "/";
}

.inner_intro h1 {
    color: #fff;
}

/* Comparison */
.Weedo-comparison .section-header-left h3 {
    margin-bottom: 15px;
}

.Weedo-comparison .container .row {
    align-self: center;
    align-items: center;
}

.Weedo-comparison .pro_box {
    border: 1px solid transparent;
}

.Weedo-comparison .section-header-left h4 {
    font-weight: 600;
    font-size: 26px;
}

.Weedo-comparison .progress {
    border-radius: 0;
    height: 25px;
    margin-bottom: 12px;
    background-color: transparent;
}

.pro_box.has-progress {
    display: block;
}

.Weedo-comparison .product_center_compare .height-diff {
    min-height: 187px;
}

.pro_compare_txt.height-diff-3 {
    min-height: 141px;
}

.Weedo-comparison .progress-bar {
    border-radius: 7px;
    background-color: #268968;
    text-align: left;
    padding: 0 8px;
}

.Weedo-comparison .product_one_left .progress {
    justify-content: flex-end;
}

.Weedo-comparison .product_one_left .progress .progress-bar {
    align-items: flex-end;
    padding-right: 10px;
}

.Weedo-comparison .section-header-left .indica-text {
    color: #6e335e;
}

.Weedo-comparison .section-header-left .color-yellow {
    color: #fdb316;
}

.Weedo-comparison .section-header-left .sativa-text {
    color: #d44727;
}

.pro_box {
    border: solid 1px #e5e5e5;
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
}

.pro_box>div.data>span {
    display: block;
    text-align: center;
    margin-bottom: 8px;
}

.pro_box.compare-image {
    display: flex;
    text-align: center;
    position: relative;
    margin: 0 0 72px;
    min-height: 340px;
    max-height: 340px;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .1);
}

.pro_box.compare-image .compare-title {
    text-align: left;
    font-size: 18px;
    width: 100%;
}

.pro_box.compare-image .code-name {
    text-align: left;
    font-size: 40px;
    font-weight: 900;
    position: relative;
    width: 100%;
}

.pro_box.compare-image .star_rating {
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
}

.compare-vs>span.vs_wrpr {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #39b54a;
    border-radius: 50%;
    font-size: 32px;
    font-weight: 600;
}

.compare-vs {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 340px;
    max-height: 340px;
    margin: 0 0 72px;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
    font-size: inherit;
    margin: 0;
}

.pro_flavor {
    font-size: 32px;
    font-weight: normal;
}

.pro_cent>span>i {
    font-weight: normal;
    font-size: 32px;
}

.pro_cent>span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro_cent>span>i {
    font-weight: normal;
    font-size: 32px;
    margin-right: 8px;
}

.pro_cent>span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro_price>div.data>span {
    margin: 0;
}

.pro_compare_txt {
    min-height: 75px;
    margin: 42px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.product_center_compare .height-diff {
    min-height: 94px;
}

.product_center_compare .height-diff-2 {
    min-height: 47px;
}

.indica_box {
    color: #fff;
    background: #6e335e;
}

.sativa_box {
    color: #fff;
    background: #d44727;
}

.hybrid_box {
    color: #fff;
    background: #77bc20;
}

.comparison_title {
    display: none;
}

.buy-now {
    color: #fff;
    margin-bottom: 0;
}

.buy-now:hover,
.buy-now:active,
.buy-now:focus {
    text-decoration: none;
    background-color: #fff;
    color: #268968;
    border-color: #268968;
}

.shop-type-2 footer.footer-type-2 {
    margin-top: 155px;
}

.shop-comparison footer.footer-type-2 {
    margin-top: 80px;
}

.pro_compare_txt:last-child {
    margin-bottom: 0;
}

.hd-social-share ul li a:hover {
    color: #28a745;
}

.mob-menu-2,
.shop-cart {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 10;
    background: #3c3e41;
    width: 20%;
    transition: all ease .4s;
    left: auto;
    right: -60%;
    display: block;
}

.mobile-open-2 .mob-menu-2,
.sidebar-open-cart .shop-cart {
    right: 0;
    left: auto;
}

.mobile-open-2 .mob-menu-overlay,
.sidebar-open-cart .mob-menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 9;
}

.mob-menu-2 .child-trigger,
.shop-cart .child-trigger {
    display: block;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 55px;
    min-width: 55px;
    height: 50px;
    border-left: none;
}

.mob-menu-1 .child-trigger i,
.mob-menu-2 .child-trigger i,
.shop-cart .child-trigger i {
    position: relative;
    top: 50%;
    margin: 0 auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.mob-menu-1 .child-trigger i,
.mob-menu-1 .child-trigger i:after,
.mob-menu-2 .child-trigger i,
.mob-menu-2 .child-trigger i:after,
.shop-cart .child-trigger i,
.shop-cart .child-trigger i:after {
    width: 10px;
    height: 2px;
    background-color: #fff;
    display: block;
}

.mob-menu-2 .mob-nav>ul>li {
    padding: 0;
    margin: 0;
}

.mob-menu-2 .mob-nav>ul ul li {
    padding: 0;
    margin: 0;
}

.mob-menu-2 .mob-nav>ul ul li a {
    display: block;
    font-weight: 500;
    color: #fff;
    line-height: 45px;
    padding: 0 40px;
}

.mob-menu-2 .mob-nav>ul {
    padding: 0;
    margin: 0;
}

.mob-menu-1 .mob-nav>ul>li>a:hover,
.mob-menu-2 .mob-nav>ul>li>a:hover {
    background-color: #28a745;
    text-decoration: none;
}

.mob-menu-1 .mob-nav li.has-child .sub-menu,
.mob-menu-2 .mob-nav li.has-child .sub-menu,
.shop-cart .mob-nav .has-child {
    display: none;
    padding: 0;
    visibility: visible;
    opacity: 1;
    min-width: auto;
    transform: none;
    position: relative;
    transition: none;
}

.shop-cart .mob-nav .has-child {
    display: block;
}

.mob-menu-2 .mob-nav>ul>li>a {
    line-height: 50px;
    padding: 0 20px;
    display: block;
    font-weight: 600;
    color: #fff;
}

.mob-menu-1 .child-trigger i:after,
.mob-menu-2 .child-trigger i:after,
.shop-cart .child-trigger i:after {
    position: absolute;
    content: '';
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.mob-menu-1 .child-trigger.child-open i:after,
.mob-menu-2 .child-trigger.child-open i:after,
.shop-cart .child-trigger.child-open i:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.mob-menu-2 .mob-nav li.has-child>a:after {
    display: none;
}

.team-social-box {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.team-social-box li {
    position: relative;
    left: -70px;
    margin-bottom: 10px;
    transition: all 900ms ease;
}

.team-social-box li a {
    position: relative;
    width: 36px;
    height: 36px;
    z-index: 1;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    background-color: #ffffff;
}

.team-social-box li .social-name {
    position: relative;
    opacity: 0;
    visibility: hidden;
    margin-left: -32px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 0px 50px 50px 0px;
    background-color: #ffffff;
    transition: all 0.3s ease;
    padding: 4px 14px 4px 15px;
    top: -1px;
}

.team-social-box li:last-child {
    margin-bottom: 0px;
}

.shop-container .item:hover .team-social-box li,
.shop-container .item:hover .team-social-box li:nth-child(2),
.shop-container .item:hover .team-social-box li:nth-child(3),
.shop-container .item:hover .team-social-box li:nth-child(4) {
    left: 0px;
}

.shop-container .item:hover .team-social-box li:nth-child(2) {
    transition-delay: 500ms;
}

.shop-container .item:hover .team-social-box li:nth-child(3) {
    transition-delay: 750ms;
}

.shop-container .item:hover .team-social-box li:nth-child(4) {
    transition-delay: 1000ms;
}

.team-social-box li:hover .social-name {
    opacity: 1;
    margin-left: -9px;
    visibility: visible;
}

.team-social-box li.youtube a {
    color: #ff0000;
}

.team-social-box li.linkedin a {
    color: #0077b5;
}

.team-social-box li.facebook a {
    color: #3b5998;
}

.team-social-box li.twitter a {
    color: #1da1f2;
}

.team-social-box li.youtube .social-name {
    background-color: #ff0000;
}

.team-social-box li.linkedin .social-name {
    background-color: #0077b5;
}

.team-social-box li.facebook .social-name {
    background-color: #3b5998;
}

.team-social-box li.twitter .social-name {
    background-color: #1da1f2;
}

/*Shop Sidebar*/
.Weedo-shop-left .hr-filter .custom-radio {
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 10px 20px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #3c3e41;
    margin-bottom: 18px;
}

.Weedo-shop-left .hr-filter .custom-radio:last-child,
.Weedo-shop-left .hr-filter .custom-radio label {
    margin-bottom: 0;
}

.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:checked,
.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:checked+label,
.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    width: 100%;
}

.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:checked+label:before,
.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 100%;
    background: #fff;
}

.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:checked+label:after,
.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #268968;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

.Weedo-shop-left .hr-filter .custom-radio [type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}

/*Aboouts us*/
.wd-about-sec .about-tile-gallery {
    position: relative;
    min-height: 620px;
}

.wd-about-sec .about-tile-gallery img {
    position: absolute;
}

.wd-about-sec .about-tile-gallery img.image-one {
    top: 0;
    left: 0;
    max-width: 400px;
    max-height: 450px;
    z-index: 1;
}

.wd-about-sec .about-tile-gallery img.image-two {
    right: 20px;
    bottom: 0;
    max-width: 400px;
    max-height: 260px;
    z-index: 2;
}

.wd-about-sec .about-tile-gallery img.image-three {
    right: 20px;
    top: 20px;
    max-width: 160px;
    z-index: 2;
}

.wd-about-sec .about-tile-gallery img.icon {
    top: 140px;
    right: 95px;
    z-index: 3;
}

.wd-about-sec .about-tile-gallery img.zero-icon {
    z-index: 4;
    left: -45px;
    bottom: 20px;
}
.wd-about-sec .about-text{
    padding-left: 25px;
}
.wd-about-sec .about-text .about-features{
    margin-top: 50px;
}
.about-features .feature-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.about-features .feature-box .progressbar-sec{
    width: 90px;
    position: relative;
    min-width: 90px;
    min-height: 90px;
        display: flex;
    align-items: center;
}
.about-features .feature-box .progressbar-sec{
    position: relative;
}
.about-features .feature-box .progressbar-sec span.icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #ea6e27;
}
.about-features .feature-box .counter{
    text-align: center;
    color: #268968;
    font-weight: 700;
    font-size: 48px;
}
.about-features .feature-box .counter span.timer{
    margin-left: 0;
}
.about-features .feature-box .desc{
    width: calc(100% - 210px);
}
.about-features .feature-box .desc h4{
    font-size: 22px;
}
/*wd-category-box*/
.product-shop-product-slider .slick-list{
    padding-bottom: 15px!important;
}
.slick-center.slick-current .wd-category-box:after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #268968;
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%)rotate(180deg);
    bottom: -15px;
}
.wd-category-box{
    text-align: center;
    background: #268968;
    color: #ffffff;
    text-transform: capitalize;
    margin: 0 auto;
    line-height: 5.5;
    border-radius: 0;
    padding: 25px 0;
    position: relative;
}
.wd-category-box .light-text{
        background: #a6ed56;
    padding: 4px 15px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
.wd-category-box .dark-text{
        background: #3e690a;
    padding: 4px 15px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
.wd-category-box .title{
    font-size: 44px;
    font-weight: 900;
    line-height: 26px;
    margin-bottom: 20px;
    justify-content: center;
    color: #ffffff;
}
.wd-category-box p{
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 15px;
    letter-spacing: 1px;
    padding-bottom: 0px;
}
.slick-arrow-1 .slick-arrow{
    left: -50px;
        top: 50%;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: #268968;
    text-align: center;
    line-height: 3.5;
    border-radius: 0;
    color: #ffffff;
    font-size: 0;
    display: flex!important;
    align-items: center;justify-content: center;
}
.slick-arrow-1 .slick-arrow.slick-next{
    left: auto;
    right: -50px;
}
.slick-arrow-1 .slick-arrow:before{
    content: 'prev';
    font-family: swiper-icons;
    font-size: 20px;
}
.slick-slider .slick-track, .slick-slider .slick-list{
    display: flex;
}
.slick-arrow-1 .slick-arrow.slick-next:before{
    content: 'next';
    font-family: swiper-icons;
    font-size: 20px;
}
.wd-category-detail .wd-category-content .ratings i{
    color: gold;
}
.wd-category-detail .wd-category-content .category-title h3{
    margin: 1.5rem 0 2rem;
    font-size: 38px;
    font-weight: 800;
    line-height: 26px;
    color: #000000;
}
.wd-category-detail .wd-category-content .category-title .category-tag{
    padding: 1px 10px;
    background: #268968;
    color: #ffffff;
    font-size: 14px;
    vertical-align: middle;
    border-radius: 0;
    display: inline-block;
}
.wd-category-detail .wd-category-features{
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid #fff;
}
.wd-category-detail .wd-category-features h5{
    font-size: 24px;
}
.wd-category-detail .wd-category-features .product-effects-inner{
        display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.wd-category-detail .wd-category-features .product-effects-inner .progress {
    height: 10px;
    background-color: #26896882;
    border-radius: 0rem;
    margin-right: 20px;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
}
.wd-category-detail .wd-category-features .product-effects-inner .progress-bar {
    border-radius: 0px;
    background-color: #268968;
}
.wd-category-detail .wd-category-features .product-effects-inner .effects-title{
    font-size: 16px;
}
.wd-category-detail .wd-category-features .product-flavors-align {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.wd-category-detail .wd-category-features .product-flavors-align .product-flavors-type {
    text-align: center;
    border: 2px solid #85bea4;
    border-radius: 0;
    padding: 1rem 0.5rem;
    width: 95px;
    height: 100px;
}
.wd-category-detail .wd-category-features .product-flavors-align .product-flavors-type img{
    margin: 0 auto;
}
.wd-category-detail .wd-category-features .product-flavors-align .product-flavors-type p {
    margin-bottom: 0;
    margin-top: 1rem;
    font-weight: 400;
    color: #a0a0a0;
}
.wd-category-boxs{
    position: relative;
}
.wd-category-boxs img{
    height:235px;
}
.wd-category-boxs .category-caption{
    background: #268968;
    text-align: center;
    padding: 20px;
    margin-left: 25px;
    width: calc(100% - 50px);
    margin-top: -30px;
    position: relative;
}
.wd-category-boxs .category-caption h5 a:hover{
    color: #ea6e27;
}
.wd-category-style-2.mt-negative{
    margin-top: -140px;
}
.wd-category-style-2.pb-extra{
    padding-bottom: 140px;
}
/*Single testimonials*/
.wd-testimonials .single-testimonial {
  background-color: #fff;
  padding: 40px 30px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.wd-testimonials .single-testimonial p{
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  text-transform: none;
  color: #3c3e41;
}
.wd-testimonials .single-testimonial:before {
    position: absolute;
    content: '';
    transition: all 0.4s ease-out 0s;
    background-color: #268968;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}
.wd-testimonials .single-testimonial:after{
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20%;
    bottom: -20px;
    content: '';
    background-color: #86bfa4;
    z-index: -2;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-out 0s;
}
.wd-testimonials .slick-current .single-testimonial:after,
.wd-testimonials .slick-current .single-testimonial:before {
    visibility: visible;
    opacity: 1;
}

.wd-testimonials .single-testimonial .testimonial-author {
    margin-top: 30px;
    display: grid;
    grid-template-rows: 1;
    grid-template-columns: 60px 1fr;
    align-items: center;
    grid-column-gap: 20px;
}
.wd-testimonials .single-testimonial .testimonial-author .author-info h5 {
    font-size: 18px;
    letter-spacing: -1px;
    line-height: 1.2;
    margin-bottom: 0;
}
.wd-testimonials .single-testimonial .testimonial-author .author-info span {
    font-size: 14px;
    color: #268968;
    line-height: 1;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}
.wd-testimonials .slick-current .single-testimonial p,
.wd-testimonials .slick-current .single-testimonial .testimonial-author .author-info h5,
.wd-testimonials .slick-current .single-testimonial .testimonial-author .author-info span {
    color: #fff;
}

.wd-testimonials .single-testimonial .quote-icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    opacity: 0.1;
    z-index: -1;
}
/*Clients*/
.clinet-section .clinet-item {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
}
/*wd-blog*/
.wd-blog .latest-post-box .post-meta {
    background-color: #268968;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
    text-align: left;
}
.wd-blog .latest-post-box .post-meta li {
    display: inline-block;
    margin-right: 10px;
}
.wd-blog .latest-post-box .post-meta li:last-child {
    margin-right: 0;
}
.wd-blog .latest-post-box .post-meta a {
    color: #fff;
}
.wd-blog .latest-post-box .post-meta a i {
    margin-right: 5px;
}
.wd-blog .latest-post-box .post-img {
    width: 100%;
    height: 235px;
}
.wd-blog .latest-post-box .post-desc {
       padding: 0px 25px;
    margin-top: 25px;
}
.wd-blog .latest-post-box .post-desc h3 {
    font-size: 22px;
    letter-spacing: -1px;
    margin-bottom: 20px;
}
.wd-blog .latest-post-box .post-desc .post-link {
    margin-top: 25px;
    font-weight: 700;
    text-transform: uppercase;
}
/*instagram-full-width*/
.instagram-full-width .insta-left{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    height: 100%;
}
.instagram-full-width .insta-left .insta-inner h2{
    font-size: 35px;
}
.instagram-full-width .insta-left .insta-inner h3{
    font-size: 26px;
}
.instagram-full-width .insta-left .insta-inner h3 i{
    margin-left: 5px;
}
.instagram-full-width .insta-img{
    height: 180px;
    overflow: hidden;
}
.instagram-full-width .insta-img a {
    position: relative;
    display: block;
    height: 100%;
}
.instagram-full-width .insta-img a img{
    transition: 0.3s;
    border-radius: 0px;
}
.instagram-full-width .insta-img a:before {
    content: '';
    background: #ffcd34;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .3s;
    z-index: 1;
}
.instagram-full-width .insta-img a:after {
    font-family: 'Font Awesome 5 Brands';
    content: "\f16d";
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: .3s;
    color: #fff;
    opacity: 0;
    z-index: 1;
}
.instagram-full-width .insta-img:hover a img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.instagram-full-width .insta-img:hover a:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.instagram-full-width .insta-img:hover a:before {
    opacity: 0.4;
}
/*wd-service-masonary*/
.wd-service-masonary{
    padding-top: 30px;
}
.wd-service-box{
    padding-bottom: 30px;
    height: 450px;
    position: relative;
    z-index: 1;
}
.wd-service-box>a:before{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 30px);
    background-color: #86bfa4;
    opacity: 0;
    content: '';
    transition: all 0.4s ease-out 0s;
    visibility: hidden;
}
.wd-service-box .service-desc{
    position: absolute;
    left: -80px;
    bottom: 30px;
    background-color: #fff;
    padding: 30px;
    transition: all 0.4s ease-out 0s;
    visibility: hidden;
    opacity: 0;
}
.wd-service-box .service-desc h4{
    text-transform: uppercase;
    font-size: 22px;
}
.wd-service-box:hover>a:before{
    visibility: visible;
    opacity: 0.5;
}
.wd-service-box:hover .service-desc {
    left: 0;
    visibility: visible;
    opacity: 1;
}
